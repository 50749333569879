$blue-bg: #003e66;
$white: #ffffff;
$black : #000000;
$brown: #513a3b;
$sky : #e5f2ff;
$dot-blue : #c9e8fb;
$brown : #513a3b;
$current: #79a4bf;

.menu-item-397-ru,
.menu-item-397-de{
	display: none!important;
}

$xl-screen: "screen and (max-width: 1600px)";
$screen1500: "screen and (max-width: 1500px)";
$lg-screen: "screen and (max-width: 1380px)";
$d1280: "screen and (max-width: 1280px)";
$d1180: "screen and (max-widsth: 1180px)";
$md-screen-p: "screen and (max-width: 1024px) and (orientation: portrait)";
$md-screen-l: "screen and (max-width: 1024px) and (orientation: landscape)";
$sm-screen-p : "screen and (max-width: 767px) and (orientation: portrait)";
$sm-screen-l : "screen and (max-width: 768px) and (orientation: landscape)";


.clearFloat{
  clear: both;
}

.font-open{
	font-family: 'Open Sans', sans-serif;
}
.font-arya{
	font-family: 'Arya', sans-serif;
}

.shadow-border{
	box-shadow: 10px 0px 36px -22px rgba(0,0,0,0.75);

}

.slogan-top{
	font-size: 55px;
    text-transform: uppercase;
    letter-spacing: 25px;
    text-align: center;
    color: #003e66;
    font-weight: 400;
    margin-bottom: 40px;
    transition: all .3s .55s ease;
}
.sub-slogan-bottom{
	font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 9px;
    font-weight: 400;
    text-align: center;
    color: #003e66;
    transition: all .3s .55s ease;
    line-height: 1.2;
}
div, section, footer{
	box-sizing: border-box;
}
.top,
.bottom{
	cursor: default;
}
@keyframes menuAdd{
	0%{
		transform: translate3d(0,-100%,0);
	}
	70%{
		transform: translate3d(0,0,0);
	}
	100%{
		transform: translate3d(0,-10%,0);
	}
}
@keyframes menuAddClose{
	0%{
		transform: translate3d(0,-10%,0);
	}
	70%{
		transform: translate3d(0,0,0);
	}
	100%{
		transform: translate3d(0,-100%,0);
	}
}
@keyframes bird-1-anim{
	0%{
		transform: translate3d(0,0,0);
	}

	10%{
		transform: translate3d(-5%, -5%, 0);
	}

	30%{
		transform: translate3d(5%, -20%, 0);
	}

	48%{
		transform: translate3d(12%, 12%, 0);
	}

	68%{
		transform: translate3d(-12%, -13%, 0);
	}

	90%{
		transform: translate3d(2%, -5%, 0);
	}
	100%{
		transform: translate3d(0,0,0);
	}
}
@keyframes bird-2-anim{
	0%{
		transform: translate3d(0,0,0);
	}
	13%{
		transform: translate3d(-5%, -10%, 0);
	}
	27%{
		transform: translate3d(5%, -5%, 0);
	}
	45%{
		transform: translate3d(-3%, -8%, 0);
	}
	70%{
		transform: translate3d(-10%, -5%, 0);
	}
	81%{
		transform: translate3d(-5%, 5%, 0);
	}
	95%{
		transform: translate3d(5%, 5%, 0);
	}
	100%{
		transform: translate3d(0,0,0);
	}
}
@keyframes fish1anim{
	0%{
		left: calc( 1.4vw - 140px);
		// bottom: 12vh;
	}

	90%{
		opacity: 1;
	}
	100%{
		left: 47vw;
		// bottom: 10vh;
		opacity: 0;
	}
}
@keyframes fish2anim{
	0%{
		left: calc( 6.3vw - 140px);
		// bottom: 12vh;
	}

	90%{
		opacity: 1;
	}
	100%{
		left: 52vw;
		// bottom: 10vh;
		opacity: 0;
	}
}
@keyframes fish3anim{
	0%{
		left: calc( 4vw - 140px);
		// bottom: 12vh;
	}

	90%{
		opacity: 1;
	}
	100%{
		left: 50vw;
		// bottom: 10vh;
		opacity: 0;
	}
}

@keyframes shipAnim{
	0%{
		// left: -10vw;
		bottom: 14vh;
	}
	5%{
		transform: rotate(-6deg);
	}
	10%{
		transform: rotate(0deg);
		
	}
	15%{
		transform: rotate(6deg);
		
	}
	20%{
		transform: rotate(0deg);
		
	}
	25%{
		transform: rotate(-6deg);
		
	}
	30%{
		transform: rotate(0deg);
		
	}
	35%{
		transform: rotate(6deg);
		
	}
	40%{
		transform: rotate(0deg);
		
	}
	45%{
		transform: rotate(-6deg);
		
	}
	50%{
		transform: rotate(0deg);
		
	}
	55%{
		transform: rotate(6deg);
		
	}
	60%{
		transform: rotate(0deg);
		
	}
	65%{
		transform: rotate(-6deg);
		
	}
	70%{
		transform: rotate(0deg);
		
	}
	75%{
		transform: rotate(6deg);
		
	}
	80%{
		transform: rotate(0deg);
		
	}
	85%{
		transform: rotate(-6deg);
		
	}
	90%{
		transform: rotate(0deg);
		
	}
	95%{
		transform: rotate(6deg);

	}
	100%{
		// left: 100vw;
		transform: rotate(0deg);
	}
}

@keyframes shipAnim2{
	0%{
		transform: rotate(-3deg);
		// left: 4vw;
	}
	2.5%{
		
		transform: rotate(0) ;
	}
	3.3%{
		// left: 4.5vw;
		// transform: ;
	}
	5.0%{
		transform: rotate(2deg);

	}
	7.5%{
		transform: rotate(0);

	}
	10%{
		transform: rotate(-3deg);
	}
	12.5%{
		
		transform: rotate(0) ;
	}
	15.0%{
		transform: rotate(2deg);

	}
	17.5%{
		transform: rotate(0);

	}
	20%{
		transform: rotate(-3deg);
	}
	22.5%{
		
		transform: rotate(0) ;
	}
	25.0%{
		transform: rotate(2deg);

	}
	27.5%{
		transform: rotate(0);

	}
	30%{
		transform: rotate(-3deg);
	}
	32.5%{
		
		transform: rotate(0) ;
	}
	35.0%{
		transform: rotate(2deg);

	}
	37.5%{
		transform: rotate(0);

	}
	40%{
		transform: rotate(-3deg);
	}
	42.5%{
		
		transform: rotate(0) ;
	}
	45.0%{
		transform: rotate(2deg);

	}
	47.5%{
		transform: rotate(0);

	}
	50%{
		transform: rotate(-3deg);
	}
	52.5%{
		
		transform: rotate(0) ;
	}
	55.0%{
		transform: rotate(2deg);

	}
	57.5%{
		transform: rotate(0);

	}
	60%{
		transform: rotate(-3deg);
	}
	72.5%{
		
		transform: rotate(0) ;
	}
	75.0%{
		transform: rotate(2deg);

	}
	77.5%{
		transform: rotate(0);

	}
	80%{
		transform: rotate(-3deg);
	}
	82.5%{
		
		transform: rotate(0) ;
	}
	85.0%{
		transform: rotate(2deg);

	}
	87.5%{
		transform: rotate(0);

	}
	90%{
		transform: rotate(-3deg);
		opacity: 1;
	}
	92.5%{
		
		transform: rotate(0) ;
	}
	95.0%{
		transform: rotate(2deg);

	}
	97.5%{
		transform: rotate(0);

	}
	100%{
		transform: rotate(-3deg);
		left: 50vw;
		opacity: 0;
	}

}

@keyframes footerFish1{
	0%{
		left: 100vw;
		opacity: 1;
	}

	90%{
		opacity: 1;
	}

	100%{
		opacity: 0;
		left: -10vw;
	}
}
@keyframes footerFish2{
	0%{
		left: -8vw;
		top: 35vh;
		opacity: 1;
	}

	95%{
		opacity: 1;
	}

	100%{
		opacity: 0;
		left: 60vw;
		top: 0;
	}
}
@keyframes footerFish3{
	0%{
		left: -6vw;
		top: 65vh;
		opacity: 1;
		transform: translateY(0) scale(1);
	}

	95%{
		opacity: 1;
		transform: translateY(-170%) scale(0.8);
	}

	100%{
		opacity: 0;
		left: 39vw;
		top: 65vh;
		transform: translateY(-200%) scale(0.8);
	}
}
@keyframes footerFish4{
	0%{
		right: -12vw;
		opacity: 1;
	}

	95%{
		opacity: 1;
	}

	100%{
		opacity: 0;
		right: 75vw;
		opacity: 0;
	}
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}