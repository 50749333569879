@import "reset";
@import "global";
html{

	&.overflow-hidden{
		overflow: hidden!important;
		height: 100%;
		position: relative;
		position: fixed!important;
	}
	&.load-page{
		overflow: hidden!important;
		height: 100%;
		position: relative;
		position: fixed!important;		
	}
}
body{
	@extend .font-open;
	-webkit-text-size-adjust: 100%;

	&.overflow-hidden{
		overflow: hidden!important;
		height: 100%;
		position: relative;
		position: fixed!important;
	}
	&.load-page{
		overflow: hidden!important;
		height: 100%;
		position: relative;
		position: fixed!important;		
	}
}

#menu-ham{
  width: 45px;
  position: absolute;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  top: 40px;
  left: 50px;
  display: none;
  z-index: 9999;

  @media #{$md-screen-p}{
  	display: block;
  }
  @media #{$sm-screen-p}{
  	top: 15px;
  	left: 15px;
  }
  @media #{$sm-screen-l}{
  	top: 15px;
  	left: 15px;
  	display: block;
  }

  &.fixed{
  	position: fixed;
  	height: 45px;
  }

	span {
	  display: block;
	  position: absolute;
	  height: 2px;
	  width: 50%;
	  background: $white;
	  opacity: 1;
	  transform: rotate(0deg);
	  transition: .25s ease-in-out;
		
		&:nth-child(even) {
		  left: 50%;
		  border-radius: 0 9px 9px 0;
		}

		&:nth-child(odd) {
		  left:0px;
		  border-radius: 9px 0 0 9px;
		}
		
		&:nth-child(1), 
		&:nth-child(2) {
		  top: 0px;
		}

		&:nth-child(3), 
		&:nth-child(4) {
		  top: 10px;
		}

		&:nth-child(5), 
		&:nth-child(6) {
		  top: 20px;
		}
	}
	&.open{
		span{
			background-color: $blue-bg;
			
			&:nth-child(1),
			&:nth-child(6) {
			  transform: rotate(45deg);
			}
			
			&:nth-child(2),
			&:nth-child(5) {
			  transform: rotate(-45deg);
			}

			&:nth-child(1) {
			  left: 5px;
			  top: 7px;
			  top: 0;
			}

			&:nth-child(2) {
			  left: calc(50% - 5px);
			  top: 7px;
			  top: 0;
			}

			&:nth-child(3) {
			  left: -50%;
			  opacity: 0;
			}

			&:nth-child(4) {
			  left: 100%;
			  opacity: 0;
			}

			&:nth-child(5) {
			  left: 5px;
			  top: 13px;
			}

			&:nth-child(6) {
			  left: calc(50% - 5px);
			  top: 13px;
			}
		}
	}
}



.menu-left{
	position: absolute;
	left: 20px;
	top: 50%;
	transform: translate3d(0,-50%,0);
	z-index: 9999;

	@media #{$md-screen-l}{
		left: 10px;
	}
	@media #{$md-screen-p}{
		display: none;
	}
	@media #{$sm-screen-l}{
		display: none;
	}
	@media #{$sm-screen-p}{
		display: none;
	}

	li{
		display: block;
		width: 15px;
		height: 15px;
		background-color: $white;
		border-radius: 50%;
		position: relative;
		margin: 20px 0;
		cursor: pointer;

		@media #{$md-screen-l}{
			width: 13px;
			height: 13px;
		}

		&.active{
			border: 2px solid $blue-bg;
			background-color: $dot-blue;
		}
		a{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
}

.front-page{
	.section{
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		padding-top: 0!important;
		background-color: transparent!important;
		padding-bottom: 0!important;

		.fp-tableCell{
			vertical-align: top;
		}
	}
	.section-1{
		background-image: url(../img/1.jpg);
		position: relative;

		@media #{$md-screen-p}{
			background-image: url(../img/1t.jpg);
		}

		.shells1{
			width: 8vw;
			position: absolute;
			bottom: 3vw;
			left: 3vw;
			z-index: 5;

			@media #{$md-screen-p}{
				width: 16vw;
			}
			@media #{$sm-screen-p}{
				width: 23vw;
			}
		}
		.shells2{
			width: 10vw;
			position: absolute;
			bottom: 3vw;
			right: 3vw;	
			z-index: 5;

			@media #{$md-screen-p}{
				width: 18vw;
			}
			@media #{$sm-screen-p}{
				width: 24vw;
			}		
		}
		
		.header{
			@extend .font-open;
			height: 18.5vh;
			background-color: $blue-bg;
			width: 100%;

			@media #{$lg-screen}{
				height: 16.5vh;
			}
			@media #{$md-screen-p}{
				height: 24vh;
			}
			@media #{$md-screen-l}{
				height: 17vw;
			}
			@media #{$sm-screen-p}{
				height: 29vh;
				top: 0;
			}
			@media #{$sm-screen-l}{
				height: 29vh;
				top: 0;
			}
// front-page
			nav{

				.menu-container{

					display: flex;
					text-align: center;
					justify-content: space-between;

					@media #{$md-screen-p}{
						position: fixed;
					    top: 0;
					    left: 0;
					    right: 0;
					    bottom: 0;
					    background-color: white;
					    z-index: 999;
					    display: none;
					    padding-top: 43vw;
					}
					@media #{$sm-screen-l}{
						position: fixed;
					    top: 0;
					    left: 0;
					    right: 0;
					    bottom: 0;
					    background-color: white;
					    z-index: 999;
					    display: none;
					    padding-top: 7vw;
					}	
					@media #{$sm-screen-p}{
						padding-top: 56vw;
					}
				}


				.menu-menu-1-container,
				.menu-menu-2-container,
				.menu-menu-1-ru-container,
				.menu-menu-2-ru-container,
				.menu-menu-1-en-container,
				.menu-menu-2-en-container,
				.menu-menu-1-de-container,
				.menu-menu-2-de-container{
					margin-top: 50px;

					@media #{$lg-screen}{
						margin-top: 25px;
					}

					@media #{$md-screen-p}{
						position: relative;
						width: 100%;
						left:0;
						padding: 0;
						opacity: 0;
					    transition: all 0.5s 0.2s ease;
					}
					@media #{$sm-screen-l}{
						position: relative;
						width: 100%;
						left:0;
						padding: 0;
						opacity: 0;
					    transition: all 0.5s 0.2s ease;
					}
					&.open{
						@media #{$md-screen-p}{
							opacity: 1;
						}
						@media #{$sm-screen-l}{
							opacity: 1;
						}
					}

					.menu{
						
						li{
							display: inline-block;
							font-size: 20px;
							font-weight: 300;
							color: $white;
							text-transform: uppercase;
							margin: 0 35px;

							@media #{$lg-screen}{
								font-size: 16px;
								margin: 0 25px;
							}
							@media #{$md-screen-p}{
								color: $blue-bg;
								display: block;
								margin: 0;
								font-size: 30px;
								margin-bottom: 30px;
								font-weight: 500;
							}	

							@media #{$sm-screen-l}{
								color: $blue-bg;
								display: block;
								margin: 0;
								font-size: 20px;
								margin-bottom: 3vw;
								font-weight: 300;
							}	
							@media #{$sm-screen-p}{
								font-size: 22px;
								font-weight: 300;
								margin-bottom: 20px;
							}

							a{
								color: $white;

								@media #{$md-screen-p}{
									color: $black;
								}
								@media #{$sm-screen-l}{
									color: $black;
								}
							}

							&.current-menu-item{
								a{
									color: $current;

									@media #{$md-screen-p}{
										color: $black;
									}
									@media #{$sm-screen-l}{
										color: $black;
									}
								}
							}
						}
					}
				}
				.menu-menu-1-container,
				.menu-menu-1-en-container,
				.menu-menu-1-ru-container,
				.menu-menu-1-de-container{
					width: 50%;

					@media #{$xl-screen}{
						width: 43%;
					}
					@media #{$md-screen-l}{
						width: 50%;
						text-align: right;
						padding-right: 0;
					}
					@media #{$md-screen-p}{
						width: 100%;
						padding: 0;
						margin-top: 0;
					}
					@media #{$sm-screen-l}{
						width: 100%;
						padding: 0;
						margin-top: 0;
						text-align: center;
					}
				}
				.menu-menu-2-container,
				.menu-menu-2-en-container,
				.menu-menu-2-ru-container,
				.menu-menu-2-de-container{
					width: 50%;

					@media #{$xl-screen}{
						width: 43%;
					}
					@media #{$md-screen-l}{
						width: 50%;
						text-align: left;
						padding-left: 0;
					}
					@media #{$md-screen-p}{
						width: 100%;
						padding: 0;
						margin-top: 0;
					}
					@media #{$sm-screen-l}{
						width: 100%;
						padding: 0;
						margin-top: 0;
						text-align: center;
					}
				}

				.logo{
					width: 23vw;
					height: 23vh;
					height: 12.5vw;
					position: absolute;
					// background-image: url(../img/logo.png);
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
					left: 0;
					right: 0;
					top: 3vh;
					margin: 0 auto;
					z-index: 9999;
					opacity: 1;
					visibility: visible;
					transition: all 0.6s ease;

					@media #{$lg-screen}{
						width: 20vw;
						height: 10.9vw;
					}

					@media #{$md-screen-p}{
						width: 50vw;
						height: 27.2vw;
					}
					@media #{$md-screen-l}{
						width: 29vw;
						height: 15.7vw;
						top: 7vw;
					}
					@media #{$sm-screen-p}{
						width: 64vw;
						height: 39.2vw;
						top: 11vw;
					}
					@media #{$sm-screen-l}{
						top: 2vw;
					}

					&.fixed{
						position: fixed;

						@media #{$sm-screen-l}{
							visibility: hidden;
							opacity: 0;
						}
					}
				}
			}

		}
		.banner-container{
			width: 100%;
			padding-top: 3.5vh;

			@media #{$md-screen-p}{
				padding-top: 13vh;
				margin-top: 0;
			}
			@media #{$md-screen-l}{
				padding-top: 7.5vw;
			}
			@media #{$sm-screen-p}{
				padding-top: 26vw;
				margin-top: 0;
			}
			@media #{$sm-screen-l}{
				padding-top: 16vh;
				margin-top: 0;
			}			

			.carousel{
				width: 86vw;
			    height: 73vh;
			    position: relative;
			    margin: 0 auto;
			    display: block;
			    border: 20px solid #ffffff;
			    border-bottom: none;
			    box-sizing: border-box;
			    z-index: 4;
				background-color: $white;
			    height: 79vh;

			    @media #{$md-screen-p}{
			    	border: 15px solid $white;
			    	border-bottom: none;
			    	width: calc(100vw - 100px);
			    	height: 76vh;
			    }
			    @media #{$md-screen-l}{
			    	border: 15px solid $white;
			    	border-bottom: 0;
			    	width: calc(100% - 70px);
			    	height: 73vh;
			    }
				@media #{$sm-screen-p}{
					height: 63vh;
					width: calc(100vw - 20px);
					border: 10px solid $white;
					border-bottom: 0;
				}
				@media #{$sm-screen-l}{
					border: 10px solid $white;
					border-bottom: none;
					width: calc(100vw - 30px);
					height: 83vh;
				}

				.carousel-inner{
					height: 73vh;
					height: 76vh;

				    @media #{$md-screen-p}{
				    	height: 76vh;
				    }
				    @media #{$md-screen-l}{
				    	height: 73vh;
				    }
				    @media #{$sm-screen-p}{
				    	height: 60.5vh;
				    }
				    @media #{$sm-screen-l}{
				    	height: 80vh;
				    }

					.carousel-item{
						height: 70vh;
						height: 83vh;
					    transition: transform .6s ease;
					    
					    @media #{$md-screen-p}{
					    	height: 73vh;
					    }

					    @media #{$md-screen-l}{
					    	height: 70vh;
					    }
					    @media #{$sm-screen-p}{
					    	height: 60.5vh;
					    }
					    @media #{$sm-screen-l}{
					    	height: 78vh;
					    }
						
						.banner-img{
							background-image: url(../img/banner.jpg);
							background-position: center 75%;
							background-size: cover;
							background-repeat: no-repeat;
							width: 100%;
							top: 0;
							left: 0;
							bottom: 0;
							right: 0;
							position: absolute;
							z-index: 10;
						}
					}
				}
				.banner-frame-bottom{
				    position: absolute;
					width: calc( 100% + 40px);
					left: -20px;
					right: 0;
					bottom: -2px;
					z-index: 5;

				    @media #{$md-screen-p}{
				    	display: none;
				    }
				    @media #{$md-screen-l}{
				    	width: calc( 100% + 30px);
				    	left: -15px;
				    }
				    @media #{$sm-screen-l}{
				    	width: calc( 100% + 20px);
				    	left: -10px;
				    }
				}
				.banner-frame-bottom-t{
				    position: absolute;
					width: calc( 100% + 40px);
					left: -15px;
					right: 0;
					bottom: 0;
					z-index: 5;
					display: none;

				    @media #{$md-screen-p}{
				    	display: block;
				    	width: calc(100vw - 100px);
				    }
				    @media #{$sm-screen-p}{
				    	width: calc( 100% + 20px);
				    	display: block;
				    	left: -10px;
				    }
				}

				.carousel-indicators{
					bottom: 7vh;
					
					@media #{$sm-screen-p}{
						bottom: 5vh;
					}

					li{
						width: 15px;
						max-width: 15px;
						height: 15px;
						border-radius: 50%;
						border: 2px solid $blue-bg;
						background-color: $white;

						@media #{$sm-screen-p}{
							width: 10px;
							height: 10px;
							max-width: 10px;
						}

						&.active{
							background-color: $dot-blue;
						}
					}
				}
			}
		}		

		.icon-bar{
			width: 100%;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			margin-top: 1.5vh;
			position: relative;

			@media #{$sm-screen-p}{
				flex-wrap: wrap;
			}

			@media #{$sm-screen-l}{
				display: none;
			}

			.icon-wrapper{
				width: 90px;
				height: 90px;
				border-radius: 50%;
				border: 1px solid transparent;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 40px;
				transition: all .4s ease;

				@media #{$lg-screen}{
					width: 70px;
					height: 70px;
					border: none;
					margin: 0 20px;
				}
				@media #{$sm-screen-p}{
					width: 63px;
					height: 63px;
				}

				.icon-container{
					width: 80px;
					height: 80px;
					border-radius: 50%;
					border: 1px dotted transparent;
					display: flex;
					justify-content: center;
					align-items: center;

					@media #{$lg-screen}{
						width: 70px;
						height: 70px;
					}
					@media #{$sm-screen-p}{
						width: 58px;
						height: 58px;
					}

					.icon{
						width: 70px;
						height: 70px;
						border-radius: 50%;
						border: 1px solid $blue-bg;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;

						@media #{$lg-screen}{
							width: 60px;
							height: 60px;
						}
						@media #{$sm-screen-p}{
							width: 53px;
							height: 53px;
						}

						.icon-img{

							@media #{$lg-screen}{
								transform: scale(0.8) translateZ(0);
							}
						}
					}
				}

				.title{
					position: absolute;
					bottom: -3vh;
					opacity: 0;
					font-weight: 14px;
					color: $blue-bg;
					text-transform: uppercase;

					@media #{$sm-screen-p}{
						display: none;
					}
				}

				&:hover{
					border: 1px solid $blue-bg;

					@media #{$lg-screen}{
						border: none;
					}
					@media #{$sm-screen-p}{
						border: 1px solid $blue-bg;
					}

					.icon-container{
						border: 1px dotted $blue-bg;						
					}
					.title{
						opacity: 1;
					}
				}

				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3){
					margin-bottom: 5px;
				}
			}
		}
	}
	.section-2{
		background-image: url(../img/2.jpg);
		background-image: url(../img/anim/bg2.jpg);

		@media #{$md-screen-p}{
			background-image: url(../img/2t.jpg);
		}
		@media #{$md-screen-l}{
			background-image: url(../img/2tl.jpg);
		}
		@media #{$sm-screen-p}{
			background-image: url(../img/2m.jpg);
		}

		&.active{
			.header{
				.top,
				.bottom{
					transform: translate3d(0,0,0);
				}
			}
			.container{
				.left,
				.right{
					transform: translate3d(0,0,0);					
				}
			}
		}

		.ship{
		    position: absolute;
		    bottom: 9vh;
		    // left: -12vw;
		    width: 14vw;
		    left: 7vw;

			@media #{$md-screen-p}{
				width: 24vw;
				bottom: 10vh;
				left: -7vw
			}
			@media #{$md-screen-l}{
				bottom: 18vh;
			    left: -6vw;
			    width: 20vw;
			}
			@media #{$sm-screen-p}{
				display: none;
			}
			@media #{$sm-screen-l}{
				display: none;
			}
		}
		.lighthouse{
			position: absolute;
			height: 80vh;
		    bottom: 10px;
		    left: 19%;
		    left: 50%;
		    transform: translatex(-50%);

			@media #{$md-screen-p}{
				display: none;
			}
			@media #{$md-screen-l}{
				display: none;
			}
		}
		.fish{
			position: absolute;
			animation: fish1anim 25s infinite linear; 
			bottom: 4vh;

			@media #{$sm-screen-p}{
				display: none;
			}
			@media #{$sm-screen-l}{
				display: none;
			}
		}

		.fish-2{
			position: absolute;
			animation: fish2anim 25s infinite linear;
			bottom: 3vh;

			@media #{$sm-screen-p}{
				display: none;
			}
			@media #{$sm-screen-l}{
				display: none;
			}			
		}
		.fish-3{
			position: absolute;
			bottom: 1vh;
			animation: fish3anim 25s infinite linear;

			@media #{$sm-screen-p}{
				display: none;
			}
			@media #{$sm-screen-l}{
				display: none;
			}
		}
		
		.header{
			@extend .font-arya;
			padding-top: 3vh;

			.top{
				font-size: 55px;
				text-transform: uppercase;
				letter-spacing: 25px;
				text-align: center;
				color: $blue-bg;
				font-weight: 400;
				margin-bottom: 40px;
				transform: translate3d(0, -50%, 0);
				transition: all .3s .7s ease;

				@media #{$lg-screen}{
					font-size: 40px;
					margin-bottom: 10px;
				}
				@media #{$md-screen-p}{
					font-size: 43px;
					letter-spacing: 11px;
				}
				@media #{$sm-screen-p}{
					font-size: 26px;
					letter-spacing: 10px;
					line-height: 1.3;
					margin-bottom: 20px;
					transform: translate3d(0, -30%, 0);
				}
				@media #{$sm-screen-l}{
					font-size: 26px;
					letter-spacing: 10px;
					line-height: 1.3;
					margin-bottom: 5px;
				}
			}
			.bottom{
				font-size: 30px;
				text-transform: uppercase;
				letter-spacing: 9px;
				font-weight: 400;
				text-align: center;
				color: $blue-bg;
				transform: translate3d(0, -50%, 0);
				transition: all .3s .7s ease;

				@media #{$lg-screen}{
					font-size: 22px;
				}
				@media #{$md-screen-p}{
				    font-size: 22px;
    				letter-spacing: 7px;
				}
				@media #{$sm-screen-p}{
				    font-size: 14px;
    				letter-spacing: 7px;
				}
				@media #{$sm-screen-l}{
				    font-size: 14px;
    				letter-spacing: 7px;
				}
			}
		}
		.container{
			@extend .font-open;
			width: 100%;
			display: flex;
			margin-top: 12vh;

			@media #{$lg-screen}{
				margin-top: 11vh;
			}
			@media #{$md-screen-p}{
				padding: 0;
				margin-top: 15vh;
			}
			@media #{$sm-screen-p}{
				display: none;
			}
			@media #{$sm-screen-l}{
				position: absolute;
				bottom: 22vh;
			}

			.left{
				width: 50%;
				position: relative;
				transform: translate3d(0, 65%, 0);
				transition: all 0.8s .5s ease;

				.left-container{
					width: 55%;
					margin: 0 auto;
					padding: 0 15px;

					@media #{$md-screen-p}{
						padding: 0;
						margin-left: 50px;
					}
					@media #{$md-screen-l}{
						width: 60%;
						padding: 0 15px 0 0;
					}

					.text{
						color: $brown;
						text-align: center;
						line-height: 1.8;
						font-size: 16px;
						cursor: default;

						@media #{$lg-screen}{
							line-height: 1.6;
    						font-size: 16px;
						}
						@media #{$md-screen-p}{
							line-height: 1.6;
    						font-size: 14px;
						}
						@media #{$md-screen-l}{
							line-height: 1.6;
    						font-size: 14px;
						}
						@media #{$sm-screen-l}{
							display: none;
						}

						&.bottom{
							margin-top: 25px;
						}
					}					
				}
				.btn{
					width: 254px;
					height: 62px;
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
					background-image: url(../img/btn.png);
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0 auto;
					position: absolute;
					bottom: calc(-62px - 6vh);
					left: 0;
					right: 0;
					cursor: pointer;

					@media #{$lg-screen}{
						bottom: calc(-62px - 3vh);
					}
					@media #{$md-screen-p}{
						width: 205px;
						right: auto;
						left: 50px;
					}
					@media #{$md-screen-l}{
						width: 220px;
					}
					@media #{$sm-screen-l}{
						bottom: 0;
						width: 205px;
						right: auto;
						left: 50px;
					}

					p{
						font-size: 12px;
						font-weight: 700;
						letter-spacing: 1.5px;
						color: $blue-bg;
					}
				}
			}
			.right{
				width: 50%;
				position: relative;
				transform: translate3d(0, 65%, 0);
				transition: all 0.8s .5s ease;

				.right-container{
					width: 55%;
					margin: 0 auto;
					padding: 0 15px;

					@media #{$md-screen-p}{
						padding: 0;
						margin-right: 50px;
					}
					@media #{$md-screen-l}{
						width: 60%;
						padding: 0 0 0 15px;
					}

					.text{
						color: $brown;
						text-align: center;
						line-height: 1.8;
						font-size: 16px;
						cursor: default;

						@media #{$lg-screen}{
							line-height: 1.6;
    						font-size: 16px;
						}
						@media #{$md-screen-p}{
							line-height: 1.6;
    						font-size: 14px;
						}
						@media #{$md-screen-l}{
							line-height: 1.6;
    						font-size: 14px;
						}
						@media #{$sm-screen-l}{
							display: none;
						}

						&.bottom{
							margin-top: 25px;
						}
					}					
				}
				.btn{
					width: 254px;
					height: 62px;
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
					background-image: url(../img/btn.png);
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0 auto;
					position: absolute;
					bottom: calc(-62px - 6vh);
					left: 0;
					right: 0;
					cursor: pointer;

					@media #{$lg-screen}{
						bottom: calc(-62px - 3vh);
					}
					@media #{$md-screen-p}{
						width: 205px;
						left: auto;
						right: 50px;
					}
					@media #{$md-screen-l}{
						width: 220px;
					}
					@media #{$sm-screen-l}{
						bottom: 0;
						width: 205px;
						right: 50px;
						left: auto;
					}

					p{
						font-size: 12px;
						font-weight: 700;
						letter-spacing: 1.5px;
						color: $blue-bg;
					}
				}
			}
		}
		.btn-mobile{
			display: none;
			position: absolute;
			bottom: 3vh;
			text-align: center;
			width: 100%;

			@media #{$sm-screen-p}{
				display: block;
			}
			.btn{
				width: 200px;
				height: 62px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				background-image: url(../img/btn.png);
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 auto;
				left: 0;
				right: 0;
				cursor: pointer;

				p{
					font-size: 12px;
					font-weight: 700;
					letter-spacing: 1.5px;
					color: $blue-bg;
				}
			}
		}

		&.active{
			.ship{
				animation: shipAnim 90s infinite linear;
			}
		}
	}
	.section-3{
		background-image: url(../img/3_n.jpg);

		@media #{$md-screen-l}{
			background-image: url(../img/3tl.jpg);
		}
		@media #{$sm-screen-p}{
			background-image: url(../img/3m.jpg);
		}

		&.active{
			.header{
				.top,
				.bottom{
					transform: translate3d(0,0,0);
				}
			}
		}		
		.header{
			@extend .font-arya;
			padding-top: 9vh;

				@media #{$lg-screen}{
					padding-top: 4vh;
				}
				@media #{$md-screen-l}{
					padding-top: 12vh;
				}
				@media #{$sm-screen-p}{
					padding-top: 6vh;
				}

			.top{
				font-size: 55px;
				text-transform: uppercase;
				letter-spacing: 25px;
				text-align: center;
				color: $blue-bg;
				font-weight: 400;
				margin-bottom: 40px;				
				transform: translate3d(0, -50%, 0);
				transition: all .3s .55s ease;
				cursor: pointer;

				@media #{$lg-screen}{
					font-size: 40px;
					margin-bottom: 10px;
				}
				@media #{$sm-screen-p}{				
					font-size: 26px;
					margin-bottom: 10px;
					letter-spacing: 10px;
				}
				@media #{$sm-screen-l}{				
					font-size: 26px;
					margin-bottom: 10px;
					letter-spacing: 10px;
				}
			}
			.bottom{
				font-size: 30px;
				text-transform: uppercase;
				letter-spacing: 9px;
				font-weight: 400;
				text-align: center;
				color: $blue-bg;				
				transform: translate3d(0, -50%, 0);
				transition: all .3s .55s ease;
				cursor: pointer;

				@media #{$lg-screen}{
					font-size: 22px;
				}
				@media #{$sm-screen-p}{				
					font-size: 14px;
				}
				@media #{$sm-screen-l}{				
					font-size: 14px;
				}
			}
		}		
		.foto-row-new{
			width: 66vw;
			height: 28vw;
			margin: 0 auto;
			margin-top: 12vh;

			@media #{$md-screen-p}{
				height: 80vw;
				width: 85vw;
			}
			@media #{$sm-screen-p}{
				width: calc(100vw - 70px);
				height: 60vh;
			}
			@media #{$sm-screen-l}{
				width: 75vw;
			}

			.owl-carousel{
				.owl-stage-outer{
					height: 31vw;

					@media #{$md-screen-p}{
						height: 80vw;
					}
					@media #{$sm-screen-p}{
						height: 60vh;
					}

					.owl-stage{
						.owl-item{
							width: 22vw!important;
    						height: 22vw;
    						margin-top: 2vw;
    						transition: all .6s ease;

    						@media #{$md-screen-p}{
    							width: 50vw!important;
    							height: 60vw;
    							margin-top: 8vw;
    						}
							@media #{$sm-screen-p}{
								width: calc(100vw - 70px)!important;
								margin-top: 0;
								height: 60vh;
							}
							@media #{$sm-screen-l}{
								width: 25vw!important;
							}

    						&.center{
								transform: scale(1.2) translateZ(0);
								z-index: 99;

								@media #{$sm-screen-p}{
									transform: scale(1) translateZ(0);
								}
    						}
    						&.active{
    							&:nth-child(1){
    								margin-right: -50vw;
    							}
    						}
    						.owl-item-wrapper{
    							width: 22vw!important;
    							height: 22vw;
								background-repeat: no-repeat;
								background-size: cover;
								background-position: center;
								position: relative;
								border: 18px solid $white;
								border-bottom: none;
								box-sizing: border-box;
								background-color: $white;

								@media #{$md-screen-p}{
									width: 50vw!important;
									height: 60vw;
								}
								@media #{$sm-screen-p}{
									width: 100%!important;
									height: 52vh;
									border: 15px solid $white;
									border-bottom: none;
								}
								@media #{$sm-screen-l}{
									width: 25vw!important;
									border: 8px solid $white;
									border-bottom: 0;
								}

								&:after{
									content: '';
									position: absolute;
									border: 2px solid $dot-blue;
									left: -5px;
									right: -5px;
									bottom: 0.75vw;
									top: -5px;
									z-index: 9999;
									border-radius: 2px;

									@media #{$md-screen-p}{
										bottom: 1.75vw;
									}
									@media #{$md-screen-l}{
										bottom: 0.6vw;
									}
									@media #{$sm-screen-p}{
										bottom: 2.8vw;
									}
									@media #{$sm-screen-l}{
										border: 1px solid $dot-blue;
										left: -3px;
										right: -3px;
										bottom: 5px;
										top: -3px;
									}
								}

								a{
									position: absolute;
									top: 0;
									bottom: 0;
									left: 0;
									right: 0;
									z-index: 99;
								}								
								.border-2-bottom{
									position: absolute;
									bottom: 0;
									width: calc(100% + 36px);
									left: -18px;
									right: 0;
									transform: translateY(64%);

									@media #{$sm-screen-l}{
										width: calc(100% + 16px);
										left: -8px;
									}
								}
    						}
						}
					}
				}

				.owl-nav{
					width: calc(100% + 130px);
					position: absolute;
					top: 50%;
					left: -65px;
					transform: translateY(-100%);
					display: flex;
					justify-content: space-between;

					.owl-prev{
						width: 50px;
						height: 50px;
						background-repeat: no-repeat;
						background-size: contain;
						background-position: center;
						background-image: url(../img/nav-slider.png);
						transform: scale(-1,1);
						background-color: transparent;

						&:hover{
							background-color: transparent;
						}
					}
					.owl-next{
						width: 50px;
						height: 50px;
						background-repeat: no-repeat;
						background-size: contain;
						background-position: center;
						background-image: url(../img/nav-slider.png);
						background-color: transparent;

						&:hover{
							background-color: transparent;
						}
					}
				}
				.owl-dots{
					display: none;
				}
			}
		}
	}
	.section-4{
		background-image: url(../img/4.jpg);

		@media #{$md-screen-p}{
			background-image: url(../img/4t.jpg);
		}
		@media #{$md-screen-l}{
			background-image: url(../img/4tl.jpg);
		}
		@media #{$sm-screen-p}{
			background-image: url(../img/4m.jpg);
		}

		.container-new{
			width: 85%;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			margin-top: 17vh;

			@media #{$lg-screen}{
				margin-top: 11vh;
				// transform: translateY(-50%);
			}
			@media #{$md-screen-p}{
				padding: 0 50px;
				width: 100%;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-top: 5vh;
				transform: translateY(0);
			}
			@media #{$md-screen-l}{
				padding: 0 45px;
				width: 100%;
				margin-top: 25vh;
				transform: translateY(0);
			}
			@media #{$sm-screen-p}{
				padding: 0 20px;
				padding-top: 5vh;
			}
			@media #{$sm-screen-l}{
				padding: 0;
				width: 95%;
				margin-top: 0;
				transform: translateY(37%);
			}

			.box{
				position: relative;
				@extend .shadow-border;
				background-color: $white;
				width: 26.2vw;
				height: 37vw;
				border: 20px solid $white;
				border-bottom: 0;
				border-top: 0;
				background-color: $white;

				@media #{$lg-screen}{
					height: 34vw;
				}
				@media #{$md-screen-p}{
					width: 41vw;
					height: 57vw;
				}
				@media #{$md-screen-l}{
					width: 29vw;
					height: 55vh;
				}

				@media #{$sm-screen-p}{
					width: calc(100vw - 40px);
					height: 72vh;
				}
				@media #{$sm-screen-l}{
					width: 30vw;
					height: 61vh;
					border: 15px solid $white;
					border-bottom: 0;
				}

				&:hover{
					.img-box{
						.img-box-bg{
							transform: scale(1.05) translate3d(0,0,0);

							@media #{$md-screen-l}{
								transform: scale(1) translate3d(0,0,0);
							}
							@media #{$md-screen-p}{
								transform: scale(1) translate3d(0,0,0);
							}
						}
					}
				}
						
				&:after{
					content: '';
				    position: absolute;
				    left: -5px;
				    right: -5px;
				    bottom: 22px;
				    border: 2px solid $dot-blue;
				    border-radius: 2px;
				    height: 28.7vw;

					@media #{$lg-screen}{
						height: 24.8vw;
					}

				    @media #{$md-screen-l}{
			    	    height: 28.8vw;
				    }
				    @media #{$md-screen-p}{
			    	    height: 40.6vw;
				    }
				    @media #{$sm-screen-p}{
				    	height: 55.7vh;
				    }
				    @media #{$sm-screen-l}{
			    	    border: 1px solid #c9e8fb;
					    border-radius: 2px;
					    height: 21.8vw;
					    bottom: 10px;
				    }
				}
				a{
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					z-index: 99;
				}

				.icon{
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					margin: 0 auto;
					z-index: 99;
					transform: translateY(-50%) scale(1);

					@media #{$lg-screen}{
						transform: translateY(-50%) scale(0.85);
					}

					&.horse{
						
						transform: translateY(-70%) scale(1);

						@media #{$lg-screen}{
							transform: translateY(-70%) scale(0.85);
							
						}
						@media #{$sm-screen-l}{
							transform: translateY(-70%) scale(0.85);
							
						}
					}
				}

				.title{
					@extend .font-arya;
					font-size: 35px;
					text-transform: uppercase;
					z-index: 10;
					left: 0;
					right: 0;
					position: absolute;
					text-align: center;
					display: inline-block;
					color: $blue-bg;
					letter-spacing: 8px;
					margin-top: 7vh;

					@media #{$lg-screen}{
						font-size: 27px;
					}
					@media #{$sm-screen-l}{
						font-size: 22px;
						letter-spacing: 2px;
					}
				}

				.img-border-4-bottom{
					width: 26.2vw;
					z-index: 10;
					position: absolute;
					bottom: 0;
					left: -20px;
					right: 0;
					transform: translateY(72%);

					@media #{$md-screen-p}{
						width: 41vw;
					}
					@media #{$md-screen-l}{
						width: 29vw;
					}
					@media #{$sm-screen-p}{
						width: calc(100vw - 40px);
					}
					@media #{$sm-screen-l}{
						width: 30vw;
						left: -15px;
					}
				}

				.img-box{
					width: 100%;
					height: 28vw;
					left: 0;
					right: 0;
					bottom: 28px;
					position: absolute;
					z-index: 5;
					overflow: hidden;

					@media #{$lg-screen}{
						height: 24vw;
					}
					@media #{$md-screen-p}{
						height: 39vw;
					}
					@media #{$md-screen-l}{
						height: 37vh;
					}
					@media #{$sm-screen-p}{
						height: 54vh;
					}
					@media #{$sm-screen-l}{
						bottom: 13px;
					}

					.img-box-bg{
						position: absolute;
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						transition: all .4s ease;
					}
				}
			}
			.box-2{

				@media #{$sm-screen-p}{
					display: none;
				}
			}
			.box-3{
				@media #{$md-screen-p}{
					margin: 50px auto 0;
				}

				@media #{$sm-screen-p}{
					display: none;
				}

				.img-box{
					z-index: 5;
				}
			}
		}
	}
	.section-4-1-m{
		display: none;
		background-image: url(../img/41m.jpg);

		@media #{$sm-screen-p}{
			display: block;
		}

		.container-mobile{
			padding: 0 20px;
			margin-top: 5vh;
			padding-top: 5vh;

			.box{
				position: relative;
				@extend .shadow-border;
				background-color: $white;
				width: 26.2vw;
				height: 37vw;
				border: 20px solid $white;
				border-bottom: 0;
				border-top: 0;
				background-color: $white;

				@media #{$lg-screen}{
					height: 34vw;
				}
				@media #{$md-screen-p}{
					width: 41vw;
					height: 57vw;
				}
				@media #{$md-screen-l}{
					width: 29vw;
					height: 55vh;
				}

				@media #{$sm-screen-p}{
					width: calc(100vw - 40px);
					height: 72vh;
				}
				@media #{$sm-screen-l}{
					width: 30vw;
					height: 61vh;
					border: 15px solid $white;
					border-bottom: 0;
				}
						
				&:after{
					content: '';
				    position: absolute;
				    left: -5px;
				    right: -5px;
				    bottom: 22px;
				    border: 2px solid $dot-blue;
				    border-radius: 2px;
				    height: 28.7vw;

				    @media #{$md-screen-l}{
			    	    height: 28.8vw;
				    }
				    @media #{$md-screen-p}{
			    	    height: 40.6vw;
				    }
				    @media #{$sm-screen-p}{
				    	height: 55.7vh;
				    }
				    @media #{$sm-screen-l}{
			    	    border: 1px solid #c9e8fb;
					    border-radius: 2px;
					    height: 21.8vw;
					    bottom: 10px;
				    }
				}
				a{
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					z-index: 99;
				}

				.icon{
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					margin: 0 auto;
					z-index: 99;
					transform: translateY(-50%) scale(1);

					@media #{$lg-screen}{
						transform: translateY(-50%) scale(0.85);
					}

					&.horse{
						
						transform: translateY(-70%) scale(1);

						@media #{$lg-screen}{
							transform: translateY(-70%) scale(0.85);							
						}
					}
				}

				.title{
					@extend .font-arya;
					font-size: 35px;
					text-transform: uppercase;
					z-index: 10;
					left: 0;
					right: 0;
					position: absolute;
					text-align: center;
					display: inline-block;
					color: $blue-bg;
					letter-spacing: 8px;
					margin-top: 7vh;

					@media #{$lg-screen}{
						font-size: 27px;
					}
				}

				.img-border-4-bottom{
					width: 26.2vw;
					z-index: 10;
					position: absolute;
					bottom: 0;
					left: -20px;
					right: 0;
					transform: translateY(72%);

					@media #{$md-screen-p}{
						width: 41vw;
					}
					@media #{$md-screen-l}{
						width: 29vw;
					}
					@media #{$sm-screen-p}{
						width: calc(100vw - 40px);
					}
				}

				.img-box{
					width: 100%;
					height: 28vw;
					left: 0;
					right: 0;
					bottom: 28px;
					position: absolute;
					z-index: 5;
					overflow: hidden;

					@media #{$lg-screen}{
						height: 24vw;
					}
					@media #{$md-screen-p}{
						height: 39vw;
					}
					@media #{$md-screen-l}{
						height: 37vh;
					}
					@media #{$sm-screen-p}{
						height: 54vh;
					}
					@media #{$sm-screen-l}{
						bottom: 13px;
					}

					.img-box-bg{
						position: absolute;
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						transition: all .4s ease;
					}
				}
			}
		}
	}
	.section-4-2-m{
		display: none;
		background-image: url(../img/42m.jpg);

		@media #{$sm-screen-p}{
			display: block;
		}

		.container-mobile{
			padding: 0 20px;
			margin-top: 5vh;
			padding-top: 5vh;

			.box{
				position: relative;
				@extend .shadow-border;
				background-color: $white;
				width: 26.2vw;
				height: 37vw;
				border: 20px solid $white;
				border-bottom: 0;
				border-top: 0;
				background-color: $white;

				@media #{$lg-screen}{
					height: 34vw;
				}
				@media #{$md-screen-p}{
					width: 41vw;
					height: 57vw;
				}
				@media #{$md-screen-l}{
					width: 29vw;
					height: 55vh;
				}

				@media #{$sm-screen-p}{
					width: calc(100vw - 40px);
					height: 72vh;
				}
				@media #{$sm-screen-l}{
					width: 30vw;
					height: 61vh;
					border: 15px solid $white;
					border-bottom: 0;
				}
						
				&:after{
					content: '';
				    position: absolute;
				    left: -5px;
				    right: -5px;
				    bottom: 22px;
				    border: 2px solid $dot-blue;
				    border-radius: 2px;
				    height: 28.7vw;

				    @media #{$md-screen-l}{
			    	    height: 28.8vw;
				    }
				    @media #{$md-screen-p}{
			    	    height: 40.6vw;
				    }
				    @media #{$sm-screen-p}{
				    	height: 55.7vh;
				    }
				    @media #{$sm-screen-l}{
			    	    border: 1px solid #c9e8fb;
					    border-radius: 2px;
					    height: 21.8vw;
					    bottom: 10px;
				    }
				}
				a{
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					z-index: 99;
				}

				.icon{
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					margin: 0 auto;
					z-index: 99;
					transform: translateY(-50%) scale(1);

					@media #{$lg-screen}{
						transform: translateY(-50%) scale(0.85);
					}

					&.horse{						
						transform: translateY(-70%) scale(1);

						@media #{$lg-screen}{
							transform: translateY(-70%) scale(0.85);							
						}
					}
				}

				.title{
					@extend .font-arya;
					font-size: 35px;
					text-transform: uppercase;
					z-index: 10;
					left: 0;
					right: 0;
					position: absolute;
					text-align: center;
					display: inline-block;
					color: $blue-bg;
					letter-spacing: 8px;
					margin-top: 7vh;

					@media #{$lg-screen}{
						font-size: 27px;
					}
				}

				.img-border-4-bottom{
					width: 26.2vw;
					z-index: 10;
					position: absolute;
					bottom: 0;
					left: -20px;
					right: 0;
					transform: translateY(72%);

					@media #{$md-screen-p}{
						width: 41vw;
					}
					@media #{$md-screen-l}{
						width: 29vw;
					}
					@media #{$sm-screen-p}{
						width: calc(100vw - 40px);
					}
				}

				.img-box{
					width: 100%;
					height: 28vw;
					left: 0;
					right: 0;
					bottom: 28px;
					position: absolute;
					z-index: 5;
					overflow: hidden;

					@media #{$lg-screen}{
						height: 24vw;
					}
					@media #{$md-screen-p}{
						height: 39vw;
					}
					@media #{$md-screen-l}{
						height: 37vh;
					}
					@media #{$sm-screen-p}{
						height: 54vh;
					}
					@media #{$sm-screen-l}{
						bottom: 13px;
					}

					.img-box-bg{
						position: absolute;
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						transition: all .4s ease;
					}
				}
			}
		}
	}
	.section-5{
		background-image: url(../img/bg-wb.jpg);

		@media #{$sm-screen-p}{
			background-image: url(../img/5m.jpg);
		}

		&.active{
			.header{
				.top,
				.bottom{
					transform: translate3d(0,0,0);
				}
			}
		}
		
		.bird{
			position: absolute;
		    width: 10vw;
		    right: 4%;
		    top: 12vh;
		    animation: bird-1-anim 14s infinite linear;

		    @media #{$md-screen-p}{
		    	width: 17vw;
		    	z-index: 2;
		    }
		    @media #{$md-screen-l}{
		    	z-index: 2;
		    }
		    @media #{$sm-screen-p}{
		    	width: 21vw;
		    	top: 16vh;
		    }
		}

		.bird-2{
		    position: absolute;
		    width: 8vw;
		    left: 6%;
		    top: 3vh;
		    animation: bird-2-anim 10s infinite linear;
		    	
	    	@media #{$md-screen-p}{
	    		width: 16vw;
			    left: 5%;
			    top: 5vh;
	    	}
		    @media #{$sm-screen-p}{
		    	width: 20vw;
		    	top: 13vh;
		    }
		}

		.fish{
			position: absolute;
			bottom: 10vh;
			animation: fish1anim 25s infinite linear;
		}
		.fish-2{
			position: absolute;
			bottom: 7vh;
			animation: fish2anim 25s infinite linear;
		}
		.fish-3{
			position: absolute;
			bottom: 2.5vh;
			animation: fish3anim 25s infinite linear;
		}
		.header{
			@extend .font-arya;
			padding-top: 6vh;
			margin-bottom: 8vh;

				@media #{$lg-screen}{
					padding-top: 3vh;
				}
				@media #{$md-screen-p}{
					padding-top: 4vh;
				}

			.top{
				font-size: 55px;
				text-transform: uppercase;
				letter-spacing: 25px;
				text-align: center;
				color: $blue-bg;
				font-weight: 400;
				margin-bottom: 2vh;				
				transform: translate3d(0, -50%, 0);
				transition: all .3s .6s ease;

				@media #{$lg-screen}{
					font-size: 40px;
					margin-bottom: 10px;
				}
				@media #{$sm-screen-p}{
					font-size: 26px;
					margin-bottom: 10px;
					letter-spacing: 10px;
				}
				@media #{$sm-screen-l}{
					font-size: 26px;
					letter-spacing: 10px;
					margin-bottom: 5px;
				}
			}
			.bottom{
				font-size: 30px;
				text-transform: uppercase;
				letter-spacing: 9px;
				font-weight: 400;
				text-align: center;
				color: $blue-bg;
				transform: translate3d(0, -50%, 0);
				transition: all .3s .6s ease;

				@media #{$lg-screen}{
					font-size: 22px;
				}
				@media #{$sm-screen-p}{
					font-size: 14px;
					letter-spacing: 3px;
				}
				@media #{$sm-screen-l}{
					font-size: 14px;
					letter-spacing: 3px;
				}
			}
		}
		
		.banner-container,
		.carousel{
			@extend .shadow-border;
			height: 33vw;
			height: 60vh;
			width: 62vw;
			border: 20px solid $white;
			border-bottom: 0;
			position: relative;
			margin: 0 auto;
			background-color: $white;

			@media #{$lg-screen}{
				height: 57vh;
			}
			@media #{$md-screen-p}{
				width: calc( 100% - 100px);
				margin-top: 12vh;
			}
			@media #{$md-screen-l}{
				width: calc( 100% - 180px);
				margin-top: 12vh;
			}
			@media #{$sm-screen-p}{
				width: calc( 100% - 50px);
				height: 45vh;
				margin-top: 14vh;
			}

			@media #{$sm-screen-l}{
				width: calc(90vw - 180px);
				border: 15px solid $white;
				border-bottom: 0;
			}

			&:after{
				content: '';
				position: absolute;
				left: -5px;
				top: -5px;
				right: -5px;
				bottom: -5px;
				border: 2px solid $dot-blue;
				border-radius: 2px;
				z-index: 9;

				@media #{$md-screen-l}{
					bottom: 0;
				}
				@media #{$md-screen-p}{
					bottom: 8px;
					right: -7px;
					left: -7px;
					top: -7px;
				}
			}

			.icon{
				position: absolute;
				top: 0;
				transform: translateY(-81%);
				left: 0;
				right: 0;
				margin: 0 auto;
				z-index: 99;

				@media #{$lg-screen}{
					transform: translateY(-81%) scale(0.85);					
				}
			}

			.border-bottom{
				position: absolute;
				bottom: 0;
				width: 62vw;
				right: -20px;
				left: -20px;
				transform: translateY(96%);
				
				@media #{$lg-screen}{
					width: 61.9vw;
				}
				@media #{$md-screen-p}{
					width: calc( 100% + 40px);
				}
				@media #{$md-screen-l}{
					width: calc( 100vw - 180px);
				}
				@media #{$sm-screen-l}{
					width: calc( 90vw - 180px);
					left: -15px;
				}
			}

			.carousel-inner{
				height: 60vh;
				z-index: 30;

				@media #{$lg-screen}{
					height: 57vh;
				}
				@media #{$sm-screen-p}{
					height: 42vh;
				}
				@media #{$sm-screen-l}{
					height: 54vh;
				}

				.banner{
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 25px;
					right: 0;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					background-image: url(../img/danie-2.jpg);

					@media #{$md-screen-p}{
						bottom: 35px;
					}
					@media #{$sm-screen-p}{
						background-size: cover;
						bottom: 15px;
					}
					@media #{$sm-screen-l}{
						bottom: 10px;
					}
				}

				&:hover{
					.info{
						opacity: 1;
						visibility: visible;
						@media #{$md-screen-p}{
							// display: block;
						}
						
					}
				}
			}

			.info{
				width: auto;
				max-width: 50vw;
				background-color: $white;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				padding: 25px 30px 20px 30px;
				border: 2px solid $sky;
				cursor: pointer;
				z-index: 99;
				transition: all .75s ease;
				opacity: 0;
				visibility: hidden;

				@media #{$lg-screen}{
					padding: 25px 20px 20px 20px;
					font-size: 20px;
				}
				@media #{$md-screen-p}{
					max-width: 60vw;
				}
				@media #{$md-screen-l}{
					max-width: 50vw;
				}
				@media #{$sm-screen-p}{
					max-width: 60vw;
					left: 0;
					right: 0;
					margin: 0 auto;
					transform: translate(0, -50%);
					// display: none;
					padding: 10px;
				}
				@media #{$sm-screen-l}{
					// display: none;
				}

				p{
					@extend .font-arya;
					line-height: 1.4;
				}
				a{
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					z-index: 99;
				}
				.name{
					font-size: 22px;
					text-transform: uppercase;
					text-align: center;
					letter-spacing: 2px;
					color: $blue-bg;

					@media #{$sm-screen-p}{
						font-size: 18px;
					}
				}
				.link{
					font-size: 12px;
					color: $blue-bg;
					font-weight: 700;
					text-align: center;
					margin-top: 15px;
					letter-spacing: 1px;

					@media #{$sm-screen-p}{
						margin-top: 5px;
					}
				}
			}
		}

		.carousel-item{
			transition: transform .6s ease;
		}

		.carousel-control-next{
			transform: translate3d(100%, 0, 0);
			transition: all .4s ease;

			@media #{$md-screen-p}{
				width: 50px;
				right: -25px;
			}
			@media #{$sm-screen-p}{
				width: 40px;
				right: -15px;
			}

			@media #{$sm-screen-l}{
				transform: translate3d(150%, 0, 0);
			}
		}
		.carousel-control-prev{
			transform: translate3d(-100%, 0, 0) scale(-1,1);
			transition: all .4s ease;

			@media #{$md-screen-p}{
				width: 40px;
				left: -15px;
			}
			@media #{$sm-screen-l}{
				transform: translate3d(-150%, 0, 0) scale(-1,1);
			}
		}

		.carousel-inner{
			&:hover{
				.info{
					opacity: 1;
					z-index: 99;
				}
			}
		}

		.info-bottom{
			@extend .font-arya;
			font-size: 16px;
			letter-spacing: 3px;
			text-transform: uppercase;
			color: $blue-bg;
			text-align: center;
			font-weight: 700;
			margin-top: 9vh;
			cursor: default;

			@media #{$lg-screen}{
				margin-top: 11vh;
			}
			@media #{$md-screen-l}{
				margin-top: 11vh;
			}
			@media #{$sm-screen-p}{
				letter-spacing: 1.5px;
			}
			@media #{$sm-screen-l}{
				display: none;
			}
		}
	}
	.section-6{
		background-image: url(../img/6.jpg);

		@media #{$md-screen-p}{
			background-image: url(../img/6t.jpg);
		}
		@media #{$md-screen-l}{
			background-image: url(../img/6tl.jpg);
		}
		@media #{$sm-screen-p}{
			background-image: url(../img/6m.jpg);
		}

		&.active{
			.header{
				.top,
				.bottom{
					transform: translate3d(0,0,0);
				}
			}
		}

		.header{
			@extend .font-arya;
			padding-top: 9vh;
			margin-bottom: 8vh;
			margin-bottom: 15vh;

			@media #{$md-screen-p}{
				padding-top: 12vh;
			}
			@media #{$md-screen-l}{
				padding-top: 15vh;
			}
			@media #{$sm-screen-p}{
				padding-top: 10vh;
			}

			.top{
				font-size: 55px;
				text-transform: uppercase;
				letter-spacing: 25px;
				text-align: center;
				color: $blue-bg;
				font-weight: 400;
				margin-bottom: 2vh;
				transform: translate3d(0, -50%, 0);
				transition: all .3s .6s ease;

				@media #{$lg-screen}{
					font-size: 40px;
					margin-bottom: 10px;
				}
				@media #{$sm-screen-p}{
					font-size: 26px;
					letter-spacing: 10px;
					margin-bottom: 15px;
				}
				@media #{$sm-screen-l}{
					font-size: 26px;
					letter-spacing: 10px;
					margin-bottom: 5px;
				}
			}
			.bottom{
				font-size: 30px;
				text-transform: uppercase;
				letter-spacing: 9px;
				font-weight: 400;
				text-align: center;
				color: $blue-bg;
				transform: translate3d(0, -50%, 0);
				transition: all .3s .6s ease;

				@media #{$lg-screen}{
					font-size: 22px;
				}
				@media #{$sm-screen-p}{
					font-size: 14px;
					letter-spacing: 3px;
				}
				@media #{$sm-screen-l}{
					font-size: 14px;
					letter-spacing: 3px;
				}
			}
		}

		.carousel{
			margin-top: 13vh;
			width: 67vw;
			margin: 0 auto;
			display: block;

			@media #{$lg-screen}{
				width: 62vw;
			}
			@media #{$md-screen-l}{
				width: calc(100vw - 200px);
			}
			@media #{$md-screen-p}{
				width: calc(100vw - 100px);
			}
			@media #{$md-screen-p}{
				width: calc(100vw - 70px);
			}

			.carousel-item{
				width: 67vw;
				height: 44vh;
				height: 24.2vw;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				margin: 0 auto;
			    transition: transform .6s ease;

				@media #{$lg-screen}{
					width: 62vw;
				}
				@media #{$md-screen-l}{
					width: calc(100vw - 220px);
				}
				@media #{$md-screen-p}{
					width: calc(100vw - 100px);
				}
				@media #{$sm-screen-p}{
					width: calc(100vw - 70px);
				}
			}
			.carousel-control-prev{
				transform: translate3d(100%, 0, 0);
				transition: all .4s ease;
				left: auto;
				right: 0;

				@media #{$md-screen-p}{
					width: 50px;
				}
				@media #{$md-screen-l}{
					width: 45px;
				}
				@media #{$sm-screen-p}{
					width: 35px;
				}

				img{
					transform: translateX(-50%);

					@media #{$sm-screen-p}{
						width: 15px;
					}
				}
			}
			.carousel-control-next{
				transform: translate3d(-100%, 0, 0) scale(-1,1);
				transition: all .4s ease;
				left: 0;
				right: auto;

				@media #{$md-screen-p}{
					width: 50px;
				}
				@media #{$sm-screen-p}{
					width: 35px;
				}

				img{
					transform: translateX(-50%);

					@media #{$sm-screen-p}{
						width: 15px;
					}
				}
			}

		}
	}
	.section-7{
		background-image: url(../img/7.jpg);
		position: relative;

		@media #{$md-screen-p}{
		background-image: url(../img/7t.jpg);

		}
		@media #{$sm-screen-p}{
		background-image: url(../img/7mn.jpg);

		}

		.maps-container{
			border: 20px solid $white;
			width: 86vw;
			height: 86vh;
			position: relative;
			margin: 8vh auto 0 auto;
			box-sizing: border-box;
			background-color: $white;

			@media #{$md-screen-p}{
				width: calc(100% - 100px);
				height: 74vh;
				border: 15px solid $white;
				margin-top: 18vh;
			}
			@media #{$md-screen-l}{
				width: calc( 100% - 70px);
				height: 75vh;
				margin-top: 14vh;
			}
			@media #{$sm-screen-p}{
				width: calc(100vw - 40px);
				height: 75vh;
				border: 10px solid $white;
				position: absolute;
				bottom: 20px;
				left: 0;
				right: 0;
				margin: 0 auto;
				margin-top: 0;
				top: 5vh;
				height: auto;
			}
			@media #{$sm-screen-l}{
				border: 10px solid $white;
			}

			&:after{
				content: '';
				display: block;
				position: absolute;
				top: -5px;
				right: -5px;
				bottom: -5px;
				left: -5px;
				border: 2px solid $dot-blue;
				border-radius: 2px;
			}	

			.map-overlay{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 10;
			}
			.acf-page-builder-field,
			.panel-grid,
			.panel-row-style,
			.panel-grid-cell,
			.so-panel,
			.so-widget-sow-google-map,
			.sow-google-map-canvas{
				// height: calc( 86vh - 40px)!important;
				z-index: 9;
				position: absolute!important;
				top: 0!important;
				left: 0!important;
				bottom: 0!important;
				right: 0!important;
				height: auto!important;

				// @media #{$md-screen-p}{
				// 	height: calc( 74vh - 30px )!important;
				// }
				// @media #{$md-screen-l}{
				// 	height: calc( 75vh - 40px)!important;
				// }
				// @media #{$sm-screen-p}{
				// 	height: calc(75vh - 20px)!important;
				// }
				// @media #{$sm-screen-l}{
				// 	height: calc(75vh - 20px)!important;
				// }


			}		
		}

		.about-company{
			border: 20px solid $white;
			position: absolute;
			z-index: 99;
			right: 12vw;
			top: 14vw;
			background-color: $sky;
			@extend .font-open;
			padding: 0 50px 30px 50px;

			@media #{$lg-screen}{
				padding: 0 30px 20px 30px;
			}
			@media #{$md-screen-l}{
				right: 7vw;
				top: 26vh;
				border: 15px solid $white;
				padding-top: 3vh;
			}
			@media #{$md-screen-p}{
				border: 15px solid $white;
				padding: 0 30px 10px;
				right: auto;
				left: 50%;
				transform: translateX(-50%);
				top: 12vw;
			}
			@media #{$sm-screen-p}{
				border: 10px solid $white;
				padding: 0 15px 20px;
				top: 8.5vh;
				display: none;
			}
			@media #{$sm-screen-l}{
				display: none;
			}

			&:after{
				content: '';
				display: block;
				position: absolute;
				top: -5px;
				bottom: -5px;
				left: -5px;
				right: -5px;
				border-radius: 2px;
				border: 2px solid $dot-blue;
			}

			.logo{
				width: 17.5vw;
				margin: 0 auto;
				display: block;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				top: -14%;
				z-index: 2;

				@media #{$md-screen-p}{
					width: 32vw;
					top: -27%;
				}
				@media #{$md-screen-l}{
					width: 24vw;
					top: -15%;
				}
				@media #{$sm-screen-p}{
					width: 55vw;
					top: -25%;
				}
			}

			.text{
				text-align: center;
				font-size: 20px;
				margin-top: 14vh;
				white-space: nowrap;

				@media #{$md-screen-l}{
					margin-top: 9vh;
				}
				@media #{$md-screen-p}{
					margin-top: 8vh;
				}
				@media #{$sm-screen-p}{
					font-size: 16px;
					margin-top: 11vh;
				}

				p{
					line-height: 35px;
					color: $brown;
					@media #{$sm-screen-p}{
						line-height: 1.6;
					}
				}


				p.f-line{
					@media #{$md-screen-l}{
						font-size: 16px;
					}
				}
				p.s-line{
					margin-bottom: 50px;
					@media #{$md-screen-l}{
						font-size: 16px;
					}
					@media #{$sm-screen-p}{
						margin-bottom: 30px;						
					}
				}
				p.t-line{
					font-size: 16px;
					@media #{$md-screen-l}{
						font-size: 14px;
					}
					@media #{$sm-screen-p}{
						font-size: 14px;
					}
				}
				p.fo-line{
					font-size: 16px;
					@media #{$md-screen-l}{
						font-size: 14px;
					}
					@media #{$sm-screen-p}{
						font-size: 14px;
					}
				}
			}
		}
	}
	
	.section-8{
		background-image: url(../img/8new.jpg);
		color: $white;
		position: relative;

		@media #{$md-screen-p}{
			background-image: url(../img/8t.jpg);
		}
		@media #{$md-screen-l}{
			background-image: url(../img/8tl.jpg);
		}
		@media #{$sm-screen-p}{
			background-image: url(../img/8m.jpg);
		}

		.fish1{
			width: 10vw;
			height: 10vw;
			position: absolute;
			top: 50%;
			left: 100vw;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(../img/ryby/ffish1.png);

			@media #{$md-screen-p}{
				width: 17vw;
				height: 17vw;
			}
			@media #{$md-screen-l}{
				width: 12vw;
				height: 12vw;
			}
			@media #{$sm-screen-p}{
				// animation: footerFish1 30s ease infinite;
				width: 26vw;
				height: 26vw;
			}
		}

		.fish2{
			width: 8vw;
			height: 8vw;
			position: absolute;
			top: 35vh;
			left: -8vw;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(../img/ryby/ffish2.png);

			@media #{$md-screen-p}{
				width: 15vw;
				height: 15vw;
			}
			@media #{$md-screen-l}{
				width: 11vw;
				height: 11vw;
			}
			@media #{$sm-screen-p}{
				// animation: footerFish2 30s ease infinite;
				width: 24vw;
				height: 24vw;
			}
		}
		.fish3{
			width: 4vw;
			height: 4vw;
			position: absolute;
			top: 65vh;
			left: -6vw;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(../img/ryby/ffish2.png);

			@media #{$md-screen-p}{
				width: 12vw;
				height: 12vw;
			}
			@media #{$md-screen-l}{
				width: 8vw;
				height: 8vw;
			}
			@media #{$sm-screen-p}{
				// animation: footerFish3 20s linear infinite;
				width: 16vw;
				height: 16vw;
			}
		}
		.fish4{
			width: 9vw;
			height: 9vw;
			position: absolute;
			top: 75vh;
			right: -12vw;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(../img/ryby/ffish2.png);
			transform: scale(-1,1) translate3d(0,0,0);

			@media #{$md-screen-p}{
				width: 17vw;
				height: 17vw;
			}
			@media #{$md-screen-l}{
				width: 13vw;
				height: 13vw;
			}
			@media #{$sm-screen-p}{
				animation: footerFish4 30s linear infinite;
				width: 22vw;
				height: 22vw;
			}
		}

		&.active{
			.fish1{
				animation: footerFish1 70s ease infinite;

				@media #{$sm-screen-p}{
					animation: footerFish1 30s ease infinite;
				}

			}
			.fish2{
				animation: footerFish2 40s ease-in infinite;
				
				@media #{$sm-screen-p}{
					animation: footerFish2 30s ease infinite;
				}

			}
			.fish3{
				animation: footerFish3 20s ease-in infinite;
				
				@media #{$sm-screen-p}{
					animation: footerFish3 20s linear infinite;
				}

			}
			.fish4{
				animation: footerFish4 80s linear infinite;
				
				@media #{$sm-screen-p}{
					animation: footerFish4 30s linear infinite;
				}
				@media #{$sm-screen-l}{
					animation: footerFish4 50s linear infinite;
				}
			}
		}

		.menu-left{
			li{
				&.active{
					border: 2px solid $black;
				}
			}
		}

		.footer-row{
			width: 86vw;
			margin: 0 auto;
			display: flex;
			justify-content: space-around;
			margin-top: 15vh;

			@media #{$md-screen-p}{
				width: calc( 100% - 100px);
				flex-wrap: wrap;
				justify-content: space-between;
			}
			@media #{$md-screen-l}{
				width: calc(100% - 70px);
			}
			@media #{$sm-screen-p}{
				margin-top: 5vh;
			}
			@media #{$sm-screen-l}{
				width: 100%;
				margin-top: 8vh;
			}

			.col{

				@media #{$md-screen-p}{
					width: 33%;
				}

				.title{
					@extend .font-arya;
					font-size: 30px;
					color: $white;
					margin-bottom: 3vh;
					cursor: default;

					@media #{$lg-screen}{
						font-size: 28px;
					}
					@media #{$md-screen-p}{
						font-size: 20px;
					}
					@media #{$md-screen-l}{
						font-size: 20px;
					}
					@media #{$sm-screen-p}{
						font-size: 20px;
						margin-bottom: 3vh;
					}
					@media #{$sm-screen-l}{
						margin-bottom: 5px;
					}
				}

				.box{
					@extend .font-open;
					font-weight: 300;
					font-size: 16px;

					@media #{$lg-screen}{
						font-size: 18px;
					}
					@media #{$md-screen-p}{
						font-size: 14px;
					}
					@media #{$md-screen-l}{
						font-size: 14px;
					}
					@media #{$sm-screen-p}{
						font-size: 14px;
						display: none;
						margin-bottom: 25px;
					}

					&.open{
						@media #{$sm-screen-p}{
							display: block;
						}
					}

					p{
						line-height: 1.6;
						cursor: default;

						@media #{$md-screen-l}{
							line-height: 2.0;
						}						
					}					
				}

				&.col-first{
					// flex: 0 0 20%;

					@media #{$md-screen-p}{
						flex: 0 0 100%;
						order: 3;
						margin: 0 auto;
						display: block;
					    text-align: center;
					    margin-top: 50px;
					}
					@media #{$md-screen-l}{
						flex: 0 0 20%;
					}
					@media #{$sm-screen-p}{
						flex: 0 0 100%;
						text-align: left;
						order: 0;
						margin-top: 0;
					}
					.title{
						@media #{$md-screen-p}{
							display: inline-block;
							margin-right: 20px;
						}
					}

					.box{
						display: inline-block;
						margin-bottom: 0;

						.fb-bg{
							padding: 15px;
							border-radius: 50%;
							border: 1px solid $white;
							display: inline-block;
							cursor: pointer;

							@media #{$md-screen-p}{
								display: inline-block;
							}

							img{
								width: 30px;
								height: auto;
							}
						}
					}
				}

				&.col-second{
					// flex: 0 0 20%;

					@media #{$md-screen-p}{
						flex: 0 0 25%;
					}
					@media #{$md-screen-l}{
						flex: 0 0 20%;
					}
					@media #{$sm-screen-p}{
						flex: 0 0 100%;
						order: 1;
					}
					.box{
						@extend .font-open;
						font-size: 16px;
						line-height: 1.6;

						@media #{$md-screen-p}{
							font-size: 14px;
						}
						@media #{$md-screen-l}{
							font-size: 14px;
							line-height: 2.0;
						}
						@media #{$sm-screen-p}{
							font-size: 14px;
							line-height: 2.0;
						}
						ul{
							li{
								font-weight: 300;
								
							}
						}
					}
				}
				&.col-third{
					// flex: 0 0 35%;

					@media #{$md-screen-p}{
						flex: 0 0 40%;
					}
					@media #{$md-screen-l}{
						flex: 0 0 35%;
					}
					@media #{$sm-screen-p}{
						flex: 0 0 100%;
						order: 2;
					}
					.box{
						.third-p{
							font-style: italic;
							margin-top: 30px;
							@media #{$sm-screen-l}{
								margin-top: 10px;
							}							
						}
					}
				}
				&.col-fourth{
					// flex: 0 0 25%;

					@media #{$md-screen-p}{
						flex: 0 0 35%;
					}
					@media #{$md-screen-l}{
						flex: 0 0 25%;
					}
					@media #{$sm-screen-p}{
						flex: 0 0 100%;
						order: 3;
					}
					.box{
						.phone-1{
							margin-top: 30px;
							@media #{$sm-screen-l}{
								margin-top: 10px;
							}
						}
						.email{
							margin-top: 30px;
							@media #{$sm-screen-l}{
								margin-top: 10px;
							}
						}
					}
					a{
						color: $white;
					}
				}
			}
		}

		.bottom-info{
			position: absolute;
			bottom: 5vh;
			text-align: center;
			left: 0;
			right: 0;

			img{
				margin-bottom: 30px;
			}

			p,a{
				@extend .font-open;
				font-size: 11px;
				line-height: 1.4;
				cursor: default;

				@media #{$md-screen-p}{
					font-size: 11px;
				}
				@media #{$sm-screen-l}{
					font-size: 11px;
					line-height: 1.6;
				}
			}
			a{
				cursor: pointer;
				color: $white;
			}
		}
	}
}
.map{
    width:100%;
    min-height:100%;
    margin: 0;
    padding: 0 !important;
    background: #000;
}
.acf-map{
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent;
}
.acf-map img {
   max-width: inherit !important;
}
.map {
    margin: 0;
    padding: 0 !important;
    background: #000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}
.acf-map {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.acf-map img {
   max-width: inherit !important;
}

footer.main-footer{
	background-image: url(../img/page/footer-bg.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	color: $white;
	position: relative;
	width: 100%;
	overflow: hidden;
	padding: 0 20px;
	box-sizing: border-box;

	@media #{$md-screen-p}{
		// background-image: url(../img/8t.jpg);
	}
	@media #{$md-screen-l}{
		// background-image: url(../img/8tl.jpg);
	}
	@media #{$sm-screen-p}{
		// background-image: url(../img/8m.jpg);
		height: 82vh;
	}

	&:after{
		content: '';
		position: absolute;
		top: -1px;
		width: 100%;
		height: 9px;
		left: 0;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		background-size: cover;
		background-image: url(../img/page/line.jpg);

		@media #{$md-screen-p}{
			height: 8px;
			background-size: cover;
		}
		@media #{$md-screen-l}{
			height: 8px;
			background-size: cover;
		}


	}

	.fish1{
		
		width: 10vw;
		height: 10vw;
		position: absolute;
		top: 50%;
		left: 100vw;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(../img/ryby/ffish1.png);
		animation: footerFish1 70s ease infinite;

		@media #{$md-screen-p}{
			width: 17vw;
			height: 17vw;
		}
		@media #{$md-screen-l}{
			width: 12vw;
			height: 12vw;
		}
		@media #{$sm-screen-p}{
			animation: footerFish1 30s ease infinite;
			width: 26vw;
			height: 26vw;
		}
	}

	.fish2{
		
		width: 8vw;
		height: 8vw;
		position: absolute;
		top: 35vh;
		left: -8vw;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(../img/ryby/ffish2.png);
		animation: footerFish2 40s linear infinite;

		@media #{$md-screen-p}{
			width: 15vw;
			height: 15vw;
		}
		@media #{$md-screen-l}{
			width: 11vw;
			height: 11vw;
		}
		@media #{$sm-screen-p}{
			animation: footerFish2 30s ease infinite;
			width: 24vw;
			height: 24vw;
		}
	}
	.fish3{
		
		width: 4vw;
		height: 4vw;
		position: absolute;
		top: 65vh;
		left: -6vw;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(../img/ryby/ffish2.png);
		animation: footerFish3 20s ease-in infinite;

		@media #{$md-screen-p}{
			width: 12vw;
			height: 12vw;
		}
		@media #{$md-screen-l}{
			width: 8vw;
			height: 8vw;
		}
		@media #{$sm-screen-p}{
			animation: footerFish3 20s linear infinite;
			width: 16vw;
			height: 16vw;
		}
	}
	.fish4{
		
		width: 9vw;
		height: 9vw;
		position: absolute;
		bottom: 4vh;
		right: -12vw;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(../img/ryby/ffish2.png);
		transform: scale(-1,1) translate3d(0,0,0);
		animation: footerFish4 80s linear infinite;

		@media #{$md-screen-p}{
			width: 17vw;
			height: 17vw;
		}
		@media #{$md-screen-l}{
			width: 13vw;
			height: 13vw;
		}
		@media #{$sm-screen-p}{
			animation: footerFish4 30s linear infinite;
			width: 22vw;
			height: 22vw;
		}
		@media #{$sm-screen-l}{
			animation: footerFish4 50s linear infinite;
		}
	}

	.menu-left{
		li{
			&.active{
				border: 2px solid $black;
			}
		}
	}

	.footer-row{
		width: 86vw;
		margin: 0 auto;
		display: flex;
		justify-content: space-around;
		margin-top: 15vh;
		margin-bottom: 22vw;
		z-index: 10;
		position: relative;

		@media #{$md-screen-p}{
			width: calc( 100% - 100px);
			flex-wrap: wrap;
			justify-content: space-between;
		}
		@media #{$md-screen-l}{
			width: calc(100% - 70px);
		}
		@media #{$sm-screen-p}{
			margin-top: 5vh;
		}
		@media #{$sm-screen-l}{
			width: 100%;
			margin-top: 8vh;
		}

		.col{

			@media #{$md-screen-p}{
				width: 33%;
			}

			.title{
				@extend .font-arya;
				font-size: 30px;
				color: $white;
				margin-bottom: 3vh;

				@media #{$lg-screen}{
					font-size: 28px;
				}
				@media #{$md-screen-p}{
					font-size: 20px;
				}
				@media #{$md-screen-l}{
					font-size: 20px;
				}
				@media #{$sm-screen-p}{
					font-size: 20px;
					margin-bottom: 3vh;
				}
				@media #{$sm-screen-l}{
					margin-bottom: 5px;
				}
			}

			.box{
				@extend .font-open;
				font-weight: 300;
				font-size: 16px;

				@media #{$lg-screen}{
					font-size: 18px;
				}
				@media #{$md-screen-p}{
					font-size: 14px;
				}
				@media #{$md-screen-l}{
					font-size: 14px;
				}
				@media #{$sm-screen-p}{
					font-size: 14px;
					display: none;
					margin-bottom: 25px;
				}

				&.open{
					@media #{$sm-screen-p}{
						display: block;
					}
				}

				p{
					line-height: 1.6;
					cursor: default;

					@media #{$md-screen-l}{
						line-height: 2.0;
					}						
				}					
			}

			&.col-first{
				// flex: 0 0 20%;

				@media #{$md-screen-p}{
					flex: 0 0 100%;
					order: 3;
					margin: 0 auto;
					display: block;
				    text-align: center;
				    margin-top: 50px;
				}
				@media #{$md-screen-l}{
					flex: 0 0 20%;
				}
				@media #{$sm-screen-p}{
					flex: 0 0 100%;
					text-align: left;
					order: 0;
					margin-top: 0;
				}
				.title{
					@media #{$md-screen-p}{
						display: inline-block;
						margin-right: 20px;
					}
				}

				.box{
					display: inline-block;
					margin-bottom: 0;

					.fb-bg{
						padding: 15px;
						border-radius: 50%;
						border: 1px solid $white;
						display: inline-block;
						cursor: pointer;

						@media #{$md-screen-p}{
							display: inline-block;
						}

						img{
							width: 30px;
							height: auto;
						}
					}
				}
			}

			&.col-second{
				// flex: 0 0 20%;

				@media #{$md-screen-p}{
					flex: 0 0 25%;
				}
				@media #{$md-screen-l}{
					flex: 0 0 20%;
				}
				@media #{$sm-screen-p}{
					flex: 0 0 100%;
					order: 1;
				}
				.box{
					@extend .font-open;
					font-size: 16px;
					line-height: 1.6;

					@media #{$md-screen-p}{
						font-size: 14px;
					}
					@media #{$md-screen-l}{
						font-size: 14px;
						line-height: 2.0;
					}
					@media #{$sm-screen-p}{
						font-size: 14px;
						line-height: 2.0;
					}
					ul{
						li{
							font-weight: 300;
							
						}
					}
				}
			}
			&.col-third{
				// flex: 0 0 35%;

				@media #{$md-screen-p}{
					flex: 0 0 40%;
				}
				@media #{$md-screen-l}{
					flex: 0 0 35%;
				}
				@media #{$sm-screen-p}{
					flex: 0 0 100%;
					order: 2;
				}
				.box{
					.third-p{
						font-style: italic;
						margin-top: 30px;
						@media #{$sm-screen-l}{
							margin-top: 10px;
						}							
					}
				}
			}
			&.col-fourth{
				// flex: 0 0 25%;

				@media #{$md-screen-p}{
					flex: 0 0 35%;
				}
				@media #{$md-screen-l}{
					flex: 0 0 25%;
				}
				@media #{$sm-screen-p}{
					flex: 0 0 100%;
					order: 3;
				}
				.box{
					.phone-1{
						margin-top: 30px;
						@media #{$sm-screen-l}{
							margin-top: 10px;
						}
					}
					.email{
						margin-top: 30px;
						@media #{$sm-screen-l}{
							margin-top: 10px;
						}
					}
				}
				a{
					color: $white;
				}
			}
		}
	}

	.bottom-info{
		position: absolute;
		bottom: 5vh;
		text-align: center;
		left: 0;
		right: 0;

		img{
			margin-bottom: 30px;
		}

		p,a{
			@extend .font-open;
			font-size: 11px;
			line-height: 1.4;
			cursor: default;

			@media #{$md-screen-p}{
				font-size: 11px;
			}
			@media #{$sm-screen-l}{
				font-size: 11px;
				line-height: 1.6;
			}
		}
		a{
			cursor: pointer;
			color: $white;
		}
	}
}

.page-header{
	@extend .font-open;
	height: 9vw;
	background-color: $blue-bg;
	width: 100%;

	@media #{$lg-screen}{
		height: 8.5vw;
	}
	@media #{$md-screen-p}{
		height: 24vh;
		height: 11vh;
	}
	@media #{$md-screen-l}{
		height: 17vw;
	}
	@media #{$sm-screen-p}{
		height: 17vh;
		top: 0;
	}
	@media #{$sm-screen-l}{
		height: 20vh;
		top: 0;
	}
	
// page
	nav{

		.menu-container{
			display: flex;
			text-align: center;
			justify-content: space-between;

			@media #{$md-screen-p}{
				position: fixed;
			    top: 0;
			    left: 0;
			    right: 0;
			    bottom: 0;
			    background-color: white;
			    z-index: 999;
			    display: none;
			    padding-top: 43vw;
			}
			@media #{$sm-screen-l}{
				position: fixed;
			    top: 0;
			    left: 0;
			    right: 0;
			    bottom: 0;
			    background-color: white;
			    z-index: 999;
			    display: none;
			    padding-top: 7vw;
			}	
			@media #{$sm-screen-p}{
				padding-top: 56vw;
			}		
		}

		.menu-menu-1-container,
		.menu-menu-2-container,
		.menu-menu-1-en-container,
		.menu-menu-2-en-container,
		.menu-menu-1-ru-container,
		.menu-menu-2-ru-container,
		.menu-menu-1-de-container,
		.menu-menu-2-de-container,{
			margin-top: 50px;

			@media #{$lg-screen}{
				margin-top: 25px;
			}

			@media #{$md-screen-p}{
				position: relative;
				width: 100%;
				left:0;
				padding: 0;
				opacity: 0;
			    transition: all 0.5s 0.2s ease;
			}
			@media #{$sm-screen-l}{
				position: relative;
				width: 100%;
				left:0;
				padding: 0;
				opacity: 0;
			    transition: all 0.5s 0.2s ease;
			}
			&.open{
				@media #{$md-screen-p}{
					opacity: 1;
				}
				@media #{$sm-screen-l}{
					opacity: 1;
				}
			}

			.menu{
				
				li{
					display: inline-block;
					font-size: 20px;
					font-size: 1vw;
					font-weight: 300;
					color: $white;
					text-transform: uppercase;
					margin: 0 35px;

					@media #{$lg-screen}{
						font-size: 16px;
						margin: 0 25px;
					}

					@media #{$md-screen-p}{
						color: $blue-bg;
						display: block;
						margin: 0;
						font-size: 30px;
						margin-bottom: 30px;
						font-weight: 500;
					}	

					@media #{$sm-screen-l}{
						color: $blue-bg;
						display: block;
						margin: 0;
						font-size: 20px;
						margin-bottom: 3vw;
						font-weight: 300;
					}	
					@media #{$sm-screen-p}{
						font-size: 22px;
						font-weight: 300;
						margin-bottom: 20px;
					}

					
					&.current-menu-item{
						a{
							color: $current;
						}
					}

					a{
						color: $white;

						@media #{$md-screen-p}{
							color: $black;
						}
						@media #{$sm-screen-l}{
							color: $black;
						}
					}
				}
			}
		}
		.menu-menu-1-container,
		.menu-menu-1-en-container,
		.menu-menu-1-ru-container,
		.menu-menu-1-de-container{
			width: 50%;
			padding-right: 7vw;

			@media #{$xl-screen}{
				// width: 43%;
			}
			@media #{$md-screen-l}{
				width: 50%;
				text-align: right;
				padding-right: 0;
			}
			@media #{$md-screen-p}{
				width: 100%;
				padding: 0;
				margin-top: 0;
			}
			@media #{$sm-screen-l}{
				width: 100%;
				padding: 0;
				margin-top: 0;
				text-align: center;
			}
		}
		.menu-menu-2-container,
		.menu-menu-2-en-container,
		.menu-menu-2-ru-container,
		.menu-menu-2-de-container{
			width: 50%;
			padding-left: 7vw;

			@media #{$xl-screen}{
				// width: 43%;
			}
			@media #{$md-screen-l}{
				width: 50%;
				text-align: left;
				padding-left: 0;
			}
			@media #{$md-screen-p}{
				width: 100%;
				padding: 0;
				margin-top: 0;
			}
			@media #{$sm-screen-l}{
				width: 100%;
				padding: 0;
				margin-top: 0;
				text-align: center;
			}
		}
		.logo{
			width: 23vw;
			height: 23vh;
			height: 12.5vw;
			position: absolute;
			// background-image: url(../img/logo.png);
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			left: 0;
			right: 0;
			top: 3vh;
			margin: 0 auto;
			z-index: 9999;

			@media #{$lg-screen}{
				width: 20vw;
				height: 10.9vw;
			}

			@media #{$md-screen-p}{
				width: 50vw;
				height: 27.2vw;
			}
			@media #{$md-screen-l}{
				width: 29vw;
				height: 15.7vw;
				top: 7vw;
			}
			@media #{$sm-screen-p}{
				width: 64vw;
				height: 39.2vw;
				top: 11vw;
			}
			@media #{$sm-screen-l}{
				top: 2vw;
				z-index: 99;
			}

			&.fixed{
				position: fixed;
			}

		}
	}

}


section#archive-menu{
	width: 100%;
	position: relative;
	background-image: url(../img/page/bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding-top: 8vw;

	@media #{$md-screen-p}{
		padding-top: 15vh;
	}
	@media #{$sm-screen-l}{
		padding-top: 12vw;
	}
	@media #{$sm-screen-p}{
		padding-top: 16vh;
	}


	.archive-menu-wrapper{
		.archive-menu-content{

			.header-menu-list{

				.title{

					@extend .font-arya;
					@extend .slogan-top;
					font-size: 2.8vw;
					text-transform: uppercase;
					color: $blue-bg;

					@media #{$md-screen-p}{
						font-size: 4.8vw;
						margin-bottom: 30px;
					}
					@media #{$sm-screen-p}{
						font-size: 7vw;
						letter-spacing: 17px;
					}

					@media #{$sm-screen-l}{
						font-size: 4vw;
						letter-spacing: 17px;
					}
				}
				.sub-title{
					@extend .font-arya;
					@extend .sub-slogan-bottom;
					font-size: 1.5vw;
					text-transform: uppercase;
					color: $blue-bg;

					@media #{$md-screen-p}{
						font-size: 2.5vw;
					}
					@media #{$sm-screen-p}{
						display: none;
					}
					@media #{$sm-screen-l}{
						display: none;
					}

				}
			}

			.bird1{
				position: absolute;
			    width: 10vw;
			    right: 4%;
			    top: 12vh;
			    animation: bird-1-anim 14s infinite linear;
		    	z-index: 20;

				@media #{$md-screen-p}{
					width: 13vw;
				}
				@media #{$sm-screen-p}{
					width: 16vw;
					right: 2%;
					top: 19vh;
				}
			}
			.bird2{
			    position: absolute;
			    width: 8vw;
			    left: 6%;
			    top: 3vh;
			    animation: bird-2-anim 10s infinite linear;

				@media #{$md-screen-p}{
					width: 13vw;
				}
				@media #{$sm-screen-p}{
					left: 2%;
    				top: 10vh;
				}
			}
			.shells1{
				width: 8vw;
				position: absolute;
				bottom: 3vw;
				left: 3vw;

				@media #{$md-screen-p}{
					width: 15vw;
				}
				@media #{$sm-screen-p}{
					width: 23vw;
				}
			}
			.shells2{
				width: 10vw;
				position: absolute;
				bottom: 3vw;
				right: 3vw;	

				@media #{$md-screen-p}{
					width: 18vw;
				}
				@media #{$sm-screen-p}{
					width: 24vw;
				}
				// @media #{$md-screen-p}{
				// 	width: 15vw;				
				// }		
			}

			.archive-row{
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				padding-top: 6.1vw;
				padding-bottom: 7.6vw;

				@media #{$sm-screen-p}{
					padding-bottom: 12.6vw;
				}

				article{
					width: 33%;
					box-sizing: border-box;
					margin-bottom: 50px;
					width: 26.5vw;
					height: 37.5vw;
					position: relative;
					margin: 0 1.5vw 4.8vw 1.5vw;

					@media #{$md-screen-p}{
						width: 43vw;
						height: 61vw;
					}
					@media #{$sm-screen-p}{
						width: 70vw;
						height: 61vw;
					}
					@media #{$sm-screen-p}{
						width: 69vw;
						height: 97vw;
					}
					@media #{$sm-screen-l}{
						width: 40vw;
   						height: 57vw;
					}
					.article-overlay{
						z-index: 5;
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
					}

					.article-bg{
						background-image: url(../img/page/border-page.png);
						background-repeat: no-repeat;
						background-position: center;
						background-size: contain;
						z-index: 5;
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
					}
					.article-link{
						position: absolute;
						top: 1px;
						bottom: 2.5vw;
						right: 7px;
						left: 5px;
						z-index: 12;
					}

					.article-container{
						header{
							position: relative;
							top: 0;
							left: 0;
							right: 0;
							height: 9vw;
							z-index: 10;

							@media #{$md-screen-p}{
								height: 15vw;
							}

							@media #{$sm-screen-p}{
								height: 23vw;
							}

							@media #{$sm-screen-l}{
								height: 14vw;
							}

							.title-article{
								@extend .font-arya;
								@extend .sub-slogan-bottom;
								z-index: 10;
								position: absolute;
								left: 50%;
								top: 50%;
								transform: translate3d(-50%, -50%, 0);
								width: 80%;

								@media #{$screen1500}{
									font-size: 2vw;
								}

								@media #{$md-screen-p}{
									font-size: 3.5vw;
								}

								@media #{$md-screen-l}{
									font-size: 1.8vw;
								}

								@media #{$sm-screen-p}{
									font-size: 4.5vw;
								}

								@media #{$sm-screen-l}{
									font-size: 2.8vw;
								}
							}
						}
						.article-content{
							.img-box{
								width: 100%;
								height: 30vw;
								background-repeat: no-repeat;
								background-position: center;
								background-size: cover;
								position: absolute;
								bottom: 3vw;
								left: 1vw;
								width: 24vw;

								@media #{$md-screen-p}{
									height: 45vw;
									width: 41vw;
									bottom: 5vw;
								}

								@media #{$sm-screen-p}{
									height: 68vw;
								    width: 66vw;
								    bottom: 8vw;
								}

								@media #{$sm-screen-l}{
									height: 40vw;
								    width: 37vw;
								    bottom: 5vw;
								}
							}
						}
					}
				}
			}
		}
	}
}

section#category-menu{
	width: 100%;
	position: relative;
	background-image: url(../img/page/bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding-top: 8vw;

	@media #{$md-screen-p}{
		padding-top: 20vw;
	}
	@media #{$md-screen-l}{
		padding: 8vw 20px 0 20px;
	}
	@media #{$sm-screen-p}{
		padding-top: 27vw;
	}
	@media #{$sm-screen-l}{
		padding-top: 10vw;
	}

	.category-menu-wrapper{
		width: 100%;
		width: 1500px;
		margin: 0 auto;
		padding-bottom: 4vw;

		@media #{$screen1500}{
			width: 100%;
			padding: 0 20px;
		}

		@media #{$sm-screen-p}{
			padding-bottom: 15vw;
		}
		
		.category-menu-title{
			@extend .font-arya;
			@extend .slogan-top;
			font-size: 2.8vw;
			text-transform: uppercase;
			color: $blue-bg;
			position: relative;
			padding-bottom: 4vw;
			margin-bottom: 4vw;

			@media #{$md-screen-p}{
				font-size: 4.5vw;
				letter-spacing: 17px;
			}
			@media #{$md-screen-p}{
				font-size: 7vw;
			}
			@media #{$md-screen-l}{
				font-size: 4vw;
			}

			&:after{
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				width: 100%;
				left: 0;
				right: 0;
				height: 1px;
				background-image: url(../img/page/separator.png);
				background-repeat: repeat-x;
				background-size: contain;
			}
		}

		.category-menu-container{
			width: 100%;
			display: flex;

			@media #{$md-screen-p}{
				flex-wrap: wrap;
				padding: 0 20px;
			}
			@media #{$sm-screen-p}{
				flex-wrap: wrap;
				padding: 0 10px;
			}
			@media #{$sm-screen-l}{
				flex-wrap: wrap;
				padding: 0 10px;
			}

			.category-menu-container-left{
				// width: 30%;
				padding-right: 3.6vw;

				@media #{$md-screen-p}{
					padding-right: 0;
					margin-left: 50%;
					transform: translateX(-50%);
					margin-bottom: 50px;
				}
				@media #{$sm-screen-l}{
					padding-right: 0;
					margin-left: 50%;
					transform: translateX(-50%);
					margin-bottom: 50px;
				}

				.category-menu-menu{
					ul.category-menu-left{

						li.main-li{
							@extend .font-arya;
							font-size: 1.3vw;
							margin: 1vw 0;
							color: $blue-bg;
							text-transform: uppercase;
							white-space: nowrap;

							@media #{$md-screen-p}{
								font-size: 20px;
								margin: 20px 0;
							}
							@media #{$md-screen-l}{
								font-size: 16px;
								margin: 20px 0;
							}
							@media #{$sm-screen-l}{
								font-size: 16px;
								margin: 20px 0;
							}

							
							a{
								color: $blue-bg;

							}

							&.current-menu{
								margin-bottom: 0;
							}

							ul.submenu-category{
								padding-left: 40px;
								padding: 1.8vw 0 0.8vw 1.5vw;

								li{
									@extend .font-open;
									font-size: 0.9vw;
									margin: 0.7vw 0;
									color: $brown;
									cursor: pointer;
									white-space: nowrap;

									@media #{$md-screen-p}{
										font-size: 16px;
										margin: 15px 0;
									}
									@media #{$md-screen-l}{
										font-size: 14px;
										margin: 15px 0;
									}
									@media #{$sm-screen-l}{
										font-size: 14px;
										margin: 15px 0;
									}

								}
							}
						}
					}
				}
			}

			.category-menu-container-right{
				padding-left: 3.6vw;
				width: 100%;

				@media #{$md-screen-p}{
					padding-left: 0;
				}
				@media #{$sm-screen-l}{
					padding-left: 0;
				}

				section{
					width: 100%;
					padding-bottom: 4vw;

					&:last-child{
						padding-bottom: 0;
					}

					header{
						p.title{
							@extend .font-arya;
							// @extend .slogan-top;
							text-transform: uppercase;
							letter-spacing: 7px;
							color: $blue-bg;							
							font-size: 1.8vw;
							font-weight: 400;
							margin-bottom: 3vw;

							@media #{$md-screen-p}{
								font-size: 20px;
							}
							@media #{$md-screen-l}{
								font-size: 18px;
							}
							@media #{$sm-screen-p}{
								font-size: 18px;
								letter-spacing: 3px;   
								margin-top: 30px;
								margin-bottom: 25px;
							}
							@media #{$sm-screen-l}{
								font-size: 18px;
								letter-spacing: 3px;   
								margin-top: 30px;
								margin-bottom: 25px;
							}
						}
					}
					.container{
						article{
							// margin-bottom: 1.5vw;
							padding-bottom: 1vw;
							color: $brown;
							line-height: 1.4;

							@media #{$md-screen-p}{
								padding-bottom: 15px;
							}
							@media #{$md-screen-l}{
								padding-bottom: 15px;
							}
							.row{
								
								display: flex;
								// flex-wrap: wrap;
								justify-content: space-between;
								// border-bottom: 1px solid red;
							}

							.article-name{
								margin-bottom: 0.5vw;
								// border-bottom: none;
								position: relative;
								cursor: default;
								line-height: 1.4;


								@media #{$md-screen-p}{
									font-size: 16px;
								}
								@media #{$sm-screen-p}{
									font-size: 14px;
								}
								@media #{$sm-screen-l}{
									font-size: 14px;
								}

								&.have-desc{
									cursor: pointer;

									&:after{
										content: '';
										// display: none;
										background-image: url(../img/left-arrow.svg);
										background-repeat: no-repeat;
										background-position: center;
										background-size: contain;
										width: 15px;
										height: 16px;
										position: absolute;
										right: 0;
										top: 50%;
										transform: rotate(-90deg) translateY(110%) translateX(50%);

										@media #{$sm-screen-p}{
											left: 0;
											right: auto;
											transform: rotate(-90deg) translateY(-110%) translateX(50%);

										}
									}
								}

							}
							.article-price{
								// border-bottom: none;
								margin-left: 10px;
								width: 60px;
								text-align: right;
								white-space: nowrap;

								@media #{$md-screen-p}{
									font-size: 16px;
								}
								@media #{$sm-screen-p}{
									font-size: 14px;
									width: 55px;
									margin-left: 0;
								}
								@media #{$sm-screen-l}{
									font-size: 14px;
									width: 55px;
									margin-left: 0;
								}

							}
							.separator{
								height: 16px;
								border-bottom: dotted 1px $brown;
								margin-left: 20px;

								@media #{$sm-screen-p}{
									height: 14px;
									margin-left: 10px;
								}

								@media #{$sm-screen-l}{
									height: 14px;
									margin-left: 10px;
								}
							}
							.article-desc{
								width: 90%;
								// margin-top: 1.7vw;
								margin-bottom: 1.1vw;
								// display: none;
								line-height: 1.4;

								p{
									line-height: 1.4;

									@media #{$md-screen-p}{
										font-size: 16px;
									}
									@media #{$sm-screen-p}{
										font-size: 14px;
									}
									@media #{$sm-screen-l}{
										font-size: 14px;
									}
								}
							}
						}						
					}
					footer{
						display: flex;
						margin-top: 40px;
						margin-bottom: 40px;

						@media #{$md-screen-p}{
							flex-wrap: wrap;
						}
						@media #{$md-screen-l}{
							
							flex-wrap: wrap;
						}

						.img-box{
							background-size: cover;
							background-repeat: no-repeat;
							background-position: center;
							width: 23%;
							height: 200px;
							margin-left: 2.666%;
							border: solid 12px $white;
							position: relative;


							@media #{$md-screen-p}{
								width: 48%;
								margin-left: 4%; 
								margin-bottom: 4%;
							}
							@media #{$md-screen-l}{
								
								width: 48%;
								margin-left: 4%; 
								margin-bottom: 4%;
							}

							@media #{$sm-screen-p}{
								width: 100%;
								margin: 0;
							}

							&:first-child(){
								margin-left: 0;

								@media #{$md-screen-p}{
									margin-left: 0%;
								}
								@media #{$md-screen-l}{									
									margin-left: 0%;
								}
								
							}

							&:nth-child(2){
								@media #{$sm-screen-p}{
									display: none;
								}
							}
							&:nth-child(3){

								@media #{$md-screen-p}{
									margin-left: 0%;
									margin-bottom: 0;
								}
								@media #{$md-screen-l}{									
									margin-left: 0%;
									margin-bottom: 0;
								}
								@media #{$sm-screen-p}{
									display: none;
								}
								
							}

							&:nth-child(4){

								@media #{$md-screen-p}{
									margin-bottom: 0%;
								}
								@media #{$md-screen-l}{									
									margin-bottom: 0%;
								}
								@media #{$sm-screen-p}{
									display: none;
								}
								
							}

							&:after{
								content: '';
								position: absolute;
								top: -5px;
								left: -5px;
								right: -5px;
								bottom: -5px;
								border: solid 2px $sky;
								border-radius: 5px;

							}
						}
					}

				}
			}
		}
	}
}
section#poradnik{
	width: 100%;
	position: relative;
	background-image: url(../img/page/bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding-top: 8vw;

	@media #{$md-screen-p}{
		padding-top: 20vw;
	}
	@media #{$md-screen-l}{
		padding: 8vw 20px 0 20px;
	}
	@media #{$sm-screen-p}{
		padding-top: 27vw;
	}
	@media #{$sm-screen-l}{
		padding: 10vw 10px 0 10px;
	}

	.poradnik-wrapper{
		width: 100%;
		width: 1500px;
		margin: 0 auto;
		padding-bottom: 4vw;

		@media #{$screen1500}{
			width: 100%;
			padding: 0 20px 4vw;
		}
		@media #{$md-screen-p}{
			padding-bottom: 10vw;
		}
		@media #{$md-screen-l}{
			padding: 0 0 10vw 0;
		}

		@media #{$sm-screen-p}{
			padding: 0 5px 15vw 5px;
		}
		
		.poradnik-title{
			@extend .font-arya;
			@extend .slogan-top;
			font-size: 2.8vw;
			text-transform: uppercase;
			color: $blue-bg;
			position: relative;
			padding-bottom: 4vw;
			margin-bottom: 4vw;

			@media #{$md-screen-p}{
				font-size: 4.5vw;
				letter-spacing: 17px;
			}
			@media #{$md-screen-p}{
				font-size: 7vw;
			}
			@media #{$md-screen-l}{
				font-size: 4vw;
			}

			&:after{
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				width: 100%;
				left: 0;
				right: 0;
				height: 1px;
				background-image: url(../img/page/separator.png);
				background-repeat: repeat-x;
				background-size: contain;
			}
		}

		.poradnik-container{
			width: 100%;
			display: flex;

			@media #{$md-screen-p}{
				flex-wrap: wrap;
				padding: 0 20px;
			}
			@media #{$sm-screen-p}{
				flex-wrap: wrap;
				padding: 0 10px;
			}
			@media #{$sm-screen-l}{
				flex-wrap: wrap;
				padding: 0 10px;
			}

			.poradnik-container-left{
				width: 25%;
				padding-right: 3.6vw;

				@media #{$md-screen-p}{
					padding-right: 0;
					margin-left: 50%;
					transform: translateX(-50%);
					margin-bottom: 50px;
					width: auto;
				}
				@media #{$sm-screen-l}{
					padding-right: 0;
					margin-left: 50%;
					transform: translateX(-50%);
					margin-bottom: 50px;
					width: auto;
				}

				@media #{$sm-screen-p}{
					margin-left: 0;
					transform: translateX(0);
				}

				.poradnik-menu{
					ul.poradnik-left{

						li.main-li{
							@extend .font-arya;
							font-size: 1.3vw;
							margin: 1vw 0;
							color: $blue-bg;
							text-transform: uppercase;
							line-height: 1.4;

							@media #{$md-screen-p}{
								font-size: 20px;
								margin: 20px 0;
							}
							@media #{$md-screen-l}{
								font-size: 16px;
								margin: 20px 0;
							}
							@media #{$sm-screen-l}{
								font-size: 16px;
								margin: 20px 0;
							}

							
							a{
								color: $blue-bg;

							}

							&.current-menu{
								margin-bottom: 0;
							}

							ul.submenu-category{
								padding-left: 40px;
								padding: 1.8vw 0 0.8vw 1.5vw;

								li{
									@extend .font-open;
									font-size: 0.9vw;
									margin: 0.7vw 0;
									color: $brown;
									cursor: pointer;
									line-height: 1.4;

									@media #{$md-screen-p}{
										font-size: 16px;
										margin: 15px 0;
									}
									@media #{$md-screen-l}{
										font-size: 14px;
										margin: 15px 0;
									}
									@media #{$sm-screen-l}{
										font-size: 14px;
										margin: 15px 0;
									}

								}
							}
						}
					}
				}
			}

			.poradnik-container-right{
				padding-left: 3.6vw;
				width: 75%;

				@media #{$md-screen-p}{
					padding-left: 0;
					width: 100%;
				}
				@media #{$sm-screen-l}{
					padding-left: 0;
					width: 100%;
				}

				section{
					width: 100%;
					padding-bottom: 4vw;
					line-height: 1.5;
					color: $brown;
					font-size: 18px;

					@media #{$md-screen-p}{
						line-height: 1.6;
						font-size: 18px;
					}
					@media #{$md-screen-l}{
						line-height: 1.6;
						font-size: 16px;
					}

					&:last-child{
						// padding-bottom: 0;
					}

					p{
						line-height: 1.5;
						color: $brown;
						font-size: 18px;
						font-weight: 400;

						@media #{$md-screen-p}{
							line-height: 1.6;
							font-size: 18px;
						}
						@media #{$md-screen-l}{
							line-height: 1.6;
							font-size: 16px;
						}
					}

					strong{
						font-weight: 600;
					}
					em{
						font-style: italic;
					}

					header{
						p.title{
							@extend .font-arya;
							// @extend .slogan-top;
							text-transform: uppercase;
							letter-spacing: 7px;
							color: $blue-bg;							
							font-size: 1.8vw;
							font-weight: 400;
							margin-bottom: 3vw;

							@media #{$md-screen-p}{
								font-size: 25px;
								text-align: center;
							}
							@media #{$md-screen-l}{
								font-size: 22px;
							}
							@media #{$sm-screen-p}{
								font-size: 18px;
								letter-spacing: 3px;   
								margin-top: 30px;
								margin-bottom: 25px;
							}
							@media #{$sm-screen-l}{
								font-size: 18px;
								letter-spacing: 3px;   
								margin-top: 30px;
								margin-bottom: 25px;
							}
						}
					}
					.container{
						img{
							display: block;
							margin-bottom: 35px;
							
							@media #{$md-screen-p}{
								margin: 0 auto 35px auto;
							}
							@media #{$md-screen-l}{
								margin: 0 auto 35px auto;
							}
						}	
						.panel-grid{
							margin-top: 75px;
							p{
								line-height: 1.5;
								color: $brown;
								font-size: 18px;

								@media #{$md-screen-p}{
									line-height: 1.6;
									font-size: 18px;
								}
								@media #{$md-screen-l}{
									line-height: 1.6;
									font-size: 16px;
								}
							}							
						}					
					}
				}
			}
		}
	}
}
section#about{
	width: 100%;
	position: relative;
	background-image: url(../img/page/bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding-top: 8vw;

	@media #{$md-screen-p}{
		padding-top: 20vw;
	}
	@media #{$md-screen-l}{
		padding: 8vw 20px 0 20px;
	}
	@media #{$sm-screen-p}{
		padding-top: 27vw;
	}
	@media #{$sm-screen-l}{
		padding: 10vw 10px 0 10px;
	}

	.about-wrapper{
		width: 100%;
		width: 1500px;
		margin: 0 auto;
		padding-bottom: 4vw;

		@media #{$screen1500}{
			width: 100%;
			padding: 0 20px 4vw;
		}
		@media #{$md-screen-p}{
			padding-bottom: 10vw;
		}
		@media #{$md-screen-l}{
			padding: 0 0 10vw 0;
		}

		@media #{$sm-screen-p}{
			padding: 0 5px 15vw 5px;
		}
		
		.about-title{
			@extend .font-arya;
			@extend .slogan-top;
			font-size: 2.8vw;
			text-transform: uppercase;
			color: $blue-bg;
			position: relative;
			padding-bottom: 4vw;
			margin-bottom: 4vw;

			@media #{$md-screen-p}{
				font-size: 4.5vw;
				letter-spacing: 17px;
			}
			@media #{$md-screen-p}{
				font-size: 7vw;
			}
			@media #{$md-screen-l}{
				font-size: 4vw;
			}

			&:after{
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				width: 100%;
				left: 0;
				right: 0;
				height: 1px;
				background-image: url(../img/page/separator.png);
				background-repeat: repeat-x;
				background-size: contain;
			}
		}

		.about-container{
			width: 100%;
			display: flex;

			@media #{$md-screen-p}{
				flex-wrap: wrap;
				padding: 0 20px;
			}
			@media #{$sm-screen-p}{
				flex-wrap: wrap;
				padding: 0 10px;
			}
			@media #{$sm-screen-l}{
				flex-wrap: wrap;
				padding: 0 10px;
			}

			.about-container-left{
				width: 25%;
				padding-right: 3.6vw;

				@media #{$md-screen-p}{
					padding-right: 0;
					margin-left: 50%;
					transform: translateX(-50%);
					margin-bottom: 50px;
					width: auto;
				}
				@media #{$sm-screen-l}{
					padding-right: 0;
					margin-left: 50%;
					transform: translateX(-50%);
					margin-bottom: 50px;
					width: auto;
				}

				@media #{$sm-screen-p}{
					margin-left: 0;
					transform: translateX(0);
				}

				.about-menu{
					ul.about-left{

						li.main-li{
							@extend .font-arya;
							font-size: 1.3vw;
							margin: 1vw 0;
							color: $blue-bg;
							text-transform: uppercase;
							line-height: 1.4;

							@media #{$md-screen-p}{
								font-size: 20px;
								margin: 20px 0;
							}
							@media #{$md-screen-l}{
								font-size: 16px;
								margin: 20px 0;
							}
							@media #{$sm-screen-l}{
								font-size: 16px;
								margin: 20px 0;
							}

							
							a{
								color: $blue-bg;

							}

							&.current-menu{
								margin-bottom: 0;
							}

							ul.submenu-category{
								padding-left: 40px;
								padding: 1.8vw 0 0.8vw 1.5vw;

								li{
									@extend .font-open;
									font-size: 0.9vw;
									margin: 0.7vw 0;
									color: $brown;
									cursor: pointer;
									line-height: 1.4;

									@media #{$md-screen-p}{
										font-size: 16px;
										margin: 15px 0;
									}
									@media #{$md-screen-l}{
										font-size: 14px;
										margin: 15px 0;
									}
									@media #{$sm-screen-l}{
										font-size: 14px;
										margin: 15px 0;
									}

								}
							}
						}
					}
				}
			}

			.about-container-right{
				padding-left: 3.6vw;
				width: 75%;

				@media #{$md-screen-p}{
					padding-left: 0;
					width: 100%;
				}
				@media #{$sm-screen-l}{
					padding-left: 0;
					width: 100%;
				}

				section{
					width: 100%;
					padding-bottom: 4vw;

					&:last-child{
						padding-bottom: 0;
					}

					header{
						p.title{
							@extend .font-arya;
							// @extend .slogan-top;
							text-transform: uppercase;
							letter-spacing: 7px;
							color: $blue-bg;							
							font-size: 1.8vw;
							font-weight: 400;
							margin-bottom: 3vw;

							@media #{$md-screen-p}{
								font-size: 25px;
								text-align: center;
							}
							@media #{$md-screen-l}{
								font-size: 22px;
							}
							@media #{$sm-screen-p}{
								font-size: 18px;
								letter-spacing: 3px;   
								margin-top: 30px;
								margin-bottom: 25px;
							}
							@media #{$sm-screen-l}{
								font-size: 18px;
								letter-spacing: 3px;   
								margin-top: 30px;
								margin-bottom: 25px;
							}
						}
					}
					.container{
						line-height: 1.5;
						color: $brown;
						font-size: 18px;

						@media #{$md-screen-p}{
							line-height: 1.6;
							font-size: 18px;
						}
						@media #{$md-screen-l}{
							line-height: 1.6;
							font-size: 16px;
						}

						img{
							display: block;
							margin-bottom: 35px;
							
							@media #{$md-screen-p}{
								margin: 0 auto 35px auto;
							}
							@media #{$md-screen-l}{
								margin: 0 auto 35px auto;
							}
						}	
						p{
							line-height: 1.4;
							font-weight: 500;

						}
						strong{
							font-weight: 600;
						}
						em{
							font-style: italic;
						}
						h2{
							font-size: 24px;
						}
						.panel-grid{
							margin-top: 75px;
							p{
								line-height: 1.5;
								color: $brown;
								font-size: 18px;

								@media #{$md-screen-p}{
									line-height: 1.6;
									font-size: 18px;
								}
								@media #{$md-screen-l}{
									line-height: 1.6;
									font-size: 16px;
								}
							}							
						}	

						footer{
							display: flex;
							margin-top: 40px;
							margin-bottom: 40px;

							@media #{$md-screen-p}{
								flex-wrap: wrap;
							}
							@media #{$md-screen-l}{
								
								flex-wrap: wrap;
							}

							.img-box{
								background-size: cover;
								background-repeat: no-repeat;
								background-position: center;
								width: 23%;
								height: 200px;
								margin-left: 2.666%;
								border: solid 12px $white;
								position: relative;


								@media #{$md-screen-p}{
									width: 48%;
									margin-left: 4%; 
									margin-bottom: 4%;
								}
								@media #{$md-screen-l}{
									
									width: 48%;
									margin-left: 4%; 
									margin-bottom: 4%;
								}

								@media #{$sm-screen-p}{
									width: 100%;
									margin: 0;
								}

								&:first-child(){
									margin-left: 0;

									@media #{$md-screen-p}{
										margin-left: 0%;
									}
									@media #{$md-screen-l}{									
										margin-left: 0%;
									}
									
								}

								&:nth-child(2){
									@media #{$sm-screen-p}{
										display: none;
									}
								}
								&:nth-child(3){

									@media #{$md-screen-p}{
										margin-left: 0%;
										margin-bottom: 0;
									}
									@media #{$md-screen-l}{									
										margin-left: 0%;
										margin-bottom: 0;
									}
									@media #{$sm-screen-p}{
										display: none;
									}
									
								}

								&:nth-child(4){

									@media #{$md-screen-p}{
										margin-bottom: 0%;
									}
									@media #{$md-screen-l}{									
										margin-bottom: 0%;
									}
									@media #{$sm-screen-p}{
										display: none;
									}
									
								}

								&:after{
									content: '';
									position: absolute;
									top: -5px;
									left: -5px;
									right: -5px;
									bottom: -5px;
									border: solid 2px $sky;
									border-radius: 5px;

								}
							}
						}				
					}
				}
			}
		}
	}
}
section#gallery-page{
	width: 100%;
	position: relative;
	background-image: url(../img/page/bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding-top: 8vw;

	@media #{$md-screen-p}{
		padding-top: 20vw;
	}
	@media #{$md-screen-l}{
		padding: 8vw 20px 0 20px;
	}
	@media #{$sm-screen-p}{
		padding-top: 27vw;
	}
	@media #{$sm-screen-l}{
		padding: 10vw 10px 0 10px;
	}
		
	.gallery-page-wrapper{
		width: 100%;
		width: 1500px;
		margin: 0 auto;
		padding-bottom: 10vw;

		@media #{$screen1500}{
			width: 100%;
			padding: 0 20px 10vw;
		}
		@media #{$md-screen-p}{
			padding-bottom: 10vw;
		}
		@media #{$md-screen-l}{
			padding: 0 0 10vw 0;
		}

		@media #{$sm-screen-p}{
			padding: 0 5px 15vw 5px;
		}
		.gallery-page-title{
			@extend .font-arya;
			@extend .slogan-top;
			font-size: 2.8vw;
			text-transform: uppercase;
			color: $blue-bg;
			position: relative;
			padding-bottom: 4vw;
			margin-bottom: 4vw;

			@media #{$md-screen-p}{
				font-size: 4.5vw;
				letter-spacing: 17px;
			}
			@media #{$md-screen-p}{
				font-size: 7vw;
			}
			@media #{$md-screen-l}{
				font-size: 4vw;
			}


			&:after{
				content: '';
				display: block;
				position: absolute;
				bottom: 0vw;
				width: 100%;
				left: 0;
				right: 0;
				height: 1px;
				background-image: url(../img/page/separator.png);
				background-repeat: repeat-x;
				background-size: contain;
			}
		}
		.bird1{
			position: absolute;
		    width: 10vw;
		    right: 4%;
		    top: 7vh;
		    animation: bird-1-anim 14s infinite linear;
	    	z-index: 20;

			@media #{$md-screen-p}{
				width: 13vw;
			}
			@media #{$sm-screen-p}{
				width: 16vw;
				right: 2%;
				top: 14vh;
			}
		}
		.bird2{
		    position: absolute;
		    width: 8vw;
		    left: 6%;
		    top: 3vh;
		    animation: bird-2-anim 10s infinite linear;

			@media #{$md-screen-p}{
				width: 13vw;
			}
			@media #{$sm-screen-p}{
				left: 2%;
				top: 10vh;
			}
		}
		.shells1{
			width: 8vw;
			position: absolute;
			bottom: 3vw;
			left: 3vw;

			@media #{$md-screen-p}{
				width: 15vw;
			}
			@media #{$sm-screen-p}{
				width: 23vw;
			}
		}
		.shells2{
			width: 10vw;
			position: absolute;
			bottom: 3vw;
			right: 3vw;	

			@media #{$md-screen-p}{
				width: 18vw;
			}
			@media #{$sm-screen-p}{
				width: 24vw;
			}
			// @media #{$md-screen-p}{
			// 	width: 15vw;				
			// }		
		}
		.gallery-page-container{
			width: 100%;
			display: flex;

			@media #{$md-screen-p}{
				flex-wrap: wrap;
				padding: 0 20px;
			}
			@media #{$sm-screen-p}{
				flex-wrap: wrap;
				padding: 0 10px;
			}
			@media #{$sm-screen-l}{
				flex-wrap: wrap;
				padding: 0 10px;
			}
			.container{
				width: 100%;

				ul.tabs{
					margin: 0px;
					padding: 0px;
					list-style: none;
					position: relative;
					
					li{
						background: none;
						color: $brown;
						display: inline-block;
						padding: 10px 15px;
						cursor: pointer;
						font-size: 1.6vw;
						font-weight: 300;

						@media #{$md-screen-p}{
							font-size: 18px;
						}
						@media #{$md-screen-l}{
							font-size: 18px;
						}

						@media #{$sm-screen-p}{
							font-size: 16px;
						}
						@media #{$sm-screen-l}{
							font-size: 16px;
						}
					
						&.current{
							color: $blue-bg;
							font-weight: 400;
						}
					}
				}


				.tab-content{
					display: none;
					// opacity: 0;
					// visibility: hidden;
					padding: 15px;
					// transition: all 0.5s ease;
					
					&.current{
						display: inherit;
						// opacity: 1;
						// visibility: visible;
					}

					a{
						&.jg-entry{

							&:after{
								content: '';
								display: block;
								position: absolute;
								top: 4px;
								bottom: 4px;
								right: 4px;
								left: 4px;
								border: 2px solid $sky;
								z-index: 99;
								border-radius: 3px;
							}
						}
					}

					img{
						border: 10px solid $white;
						box-sizing: border-box;
						position: relative;
					}
				}
			}
		}
	}
}

.fbx-caption{
	display: none!important;
}
.fbx-rounded .fbx-item-current, .fbx-rounded .fbx-item-image, .fbx-rounded .fbx-item-next{
	border-radius: 0!important;
}
.fbx-rounded .fbx-inner{
	border-radius: 0!important;
}
.fbx-rounded .fbx-next{
	border-radius: 0!important;

	@media screen and (min-width: 1381px){
		right: -54px!important;
	}
	@media screen and (min-width: 1025px) and (max-width: 1380px){
		right: -49px!important;
	}
	// right: -54px!important;

	// @media #{$lg-screen}{
	// 	right: -49px!important;
	// }
	// @media #{$md-screen-p}{
	// 	left: 33%;
	// 	right: 33%!important;
	// 	// right: -41px!important;
	// }
	// @media #{$md-screen-l}{
	// 	right: auto!important;
	// }
	// @media #{$sm-screen-p}{
	// 	right: 0!important;
	// }
}
.fbx-rounded .fbx-prev{
	border-radius: 0!important;
	// left: -54px!important;

	@media screen and (min-width: 1381px){
		left: -54px!important;
	}
	@media screen and (min-width: 1025px) and (max-width: 1380px){
		left: -49px!important;
	}

	// @media #{$lg-screen}{
	// 	left: -49px!important;
	// }
	// @media #{$md-screen-p}{
	// 	// left: -41px!important;
	// 	left: 0!important;
 //    	right: 66%;
	// }
	// @media #{$md-screen-l}{
	// 	left: auto!important;
	// }
	// @media #{$sm-screen-p}{
	// 	left: 0!important;
	// }
}
.fbx-rounded .fbx-inner{
	border: 20px solid $white!important;
	box-sizing: border-box!important;

	@media screen and (min-width: 769px){
		margin-left: 0!important;
		transform: translate(-50%)!important;
	}
   
	@media #{$lg-screen}{
		border: 15px solid $white!important;
	}
	@media #{$md-screen-p}{
		border: 10px solid $white!important;
		box-sizing: content-box!important;
	}
	@media #{$md-screen-l}{
		border: 10px solid $white!important;
		box-sizing: content-box!important;
	}

	&:before{
		content: '';
		display: block;
		position: absolute;
		top: -10px;
		left: -10px;
		right: -10px;
		bottom: -10px;
		border: 4px solid $sky;
		border-radius: 5px;

		@media #{$lg-screen}{
			border: 2px solid $sky;
			top: -5px;
			left: -5px;
			bottom: -5px;
			right: -5px;
		}
	}
}
.fbx-rounded.fbx-light .fbx-close{
	// right: -32px!important;
	// top: -32px!important;
	@media screen and (min-width: 1381px){
		right: -31px!important;
		top: -31px!important;
	}
	@media screen and (min-width: 1025px) and (max-width: 1380px){
		right: -27px!important;
		top: -27px!important;
	}
}
section#news-page{
	width: 100%;
	position: relative;
	background-image: url(../img/page/bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding-top: 8vw;

	@media #{$md-screen-p}{
		padding-top: 20vw;
	}
	@media #{$md-screen-l}{
		padding: 8vw 20px 0 20px;
	}
	@media #{$sm-screen-p}{
		padding-top: 27vw;
	}
	@media #{$sm-screen-l}{
		padding: 10vw 10px 0 10px;
	}
		
	.news-page-wrapper{
		width: 100%;
		width: 1500px;
		margin: 0 auto;
		padding-bottom: 10vw;

		@media #{$screen1500}{
			width: 100%;
			padding: 0 20px 10vw;
		}
		@media #{$lg-screen}{
			padding-bottom: 10vw;
		}
		@media #{$md-screen-p}{
			padding-bottom: 10vw;
		}
		@media #{$md-screen-l}{
			padding: 0 0 10vw 0;
		}

		@media #{$sm-screen-p}{
			padding: 0 5px 10vw 5px;
		}
		@media #{$sm-screen-l}{
			padding: 0 5px 10vw 5px;
		}
		.news-page-title{
			@extend .font-arya;
			@extend .slogan-top;
			font-size: 2.8vw;
			text-transform: uppercase;
			color: $blue-bg;
			position: relative;
			padding-bottom: 4vw;
			margin-bottom: 0;
			text-align: center;
			position: relative;
			margin-bottom: 4vw;

			@media #{$md-screen-p}{
				font-size: 4.5vw;
				letter-spacing: 12px;
			}
			@media #{$md-screen-p}{
				font-size: 7vw;
				margin-bottom: 50px;
			}
			@media #{$md-screen-l}{
				font-size: 4vw;
				margin-bottom: 50px;
			}

			&:after{
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				width: 100%;
				left: 0;
				right: 0;
				height: 1px;
				background-image: url(../img/page/separator.png);
				background-repeat: repeat-x;
				background-size: contain;
			}
		}
		.bird1{
			position: absolute;
		    width: 10vw;
		    right: 4%;
		    top: 7vh;
		    animation: bird-1-anim 14s infinite linear;
	    	z-index: 20;

			@media #{$md-screen-p}{
				width: 13vw;
			}
			@media #{$sm-screen-p}{
				width: 16vw;
				right: 2%;
				top: 20vh;
			}
		}
		.bird2{
		    position: absolute;
		    width: 8vw;
		    left: 6%;
		    top: 3vh;
		    animation: bird-2-anim 10s infinite linear;

			@media #{$md-screen-p}{
				width: 13vw;
			}
			@media #{$sm-screen-p}{
				left: 2%;
				top: 10vh;
			}
		}
		.shells1{
			width: 8vw;
			position: absolute;
			bottom: 3vw;
			left: 3vw;

			@media #{$md-screen-p}{
				width: 15vw;
			}
			@media #{$sm-screen-p}{
				width: 23vw;
			}
		}
		.shells2{
			width: 10vw;
			position: absolute;
			bottom: 3vw;
			right: 3vw;	

			@media #{$md-screen-p}{
				width: 18vw;
			}
			@media #{$sm-screen-p}{
				width: 24vw;
			}
			// @media #{$md-screen-p}{
			// 	width: 15vw;				
			// }		
		}
		.news-page-container{
			width: 100%;
			// display: flex;

			@media #{$md-screen-p}{
				flex-wrap: wrap;
				padding: 0 20px;
			}
			@media #{$sm-screen-p}{
				flex-wrap: wrap;
				padding: 0 10px;
			}
			@media #{$sm-screen-l}{
				flex-wrap: wrap;
				padding: 0 10px;
			}

			.container{
				width: 100%;	

				section{
					width: 75%;
					display: block;
					margin: 0 auto 8vw;
					text-align: center;
					position: relative;

					@media #{$lg-screen}{
						margin-bottom: 12vw;
					}
					@media #{$md-screen-p}{
						width: 100%;
						margin-bottom: 120px;
					}
					@media #{$md-screen-l}{
						width: 100%;
						margin-bottom: 120px;
					}


					&:last-child(){
						// margin-bottom: 0;
					}


					.post-title{
						@extend .font-arya;
						text-transform: uppercase;
						letter-spacing: 7px;
						color: $blue-bg;							
						font-size: 1.8vw;
						font-weight: 400;
						margin-bottom: 3vw;

						@media #{$md-screen-p}{
							font-size: 25px;
							text-align: center;
						}
						@media #{$md-screen-l}{
							font-size: 22px;
						}
						@media #{$sm-screen-p}{
							font-size: 18px;
							letter-spacing: 3px;   
							margin-top: 30px;
							margin-bottom: 25px;
						}
						@media #{$sm-screen-l}{
							font-size: 18px;
							letter-spacing: 3px;   
							margin-top: 30px;
							margin-bottom: 25px;
						}
					}
					article{
						margin-bottom: 0.5vw;
						// border-bottom: none;
						position: relative;
						cursor: default;
						line-height: 1.4;
						font-size: 18px;
						color: $brown;


						@media #{$md-screen-p}{
							font-size: 16px;
						}
						@media #{$sm-screen-p}{
							font-size: 14px;
						}
						@media #{$sm-screen-l}{
							font-size: 14px;
						}

						p{
							line-height: 1.6;
							color: $brown;
							font-size: 18px;

							@media #{$md-screen-p}{
								font-size: 16px;
							}
							@media #{$sm-screen-p}{
								font-size: 14px;
							}
							@media #{$sm-screen-l}{
								font-size: 14px;
							}

						}
						.separator{
							content: '';
							display: block;
							position: absolute;
							bottom: -2vw;
							width: 100%;
							left: 0;
							right: 0;
							height: 1px;
							background-image: url(../img/page/separator.png);
							background-repeat: repeat-x;
							background-size: contain;

							@media #{$md-screen-p}{
								bottom: -25px;
							}
							@media #{$md-screen-l}{
								bottom: -25px;
							}
						}
						.post-date{
							position: absolute;
							bottom: -4vw;
							right: 0;
							font-size: 14px;
							color: $brown;

							@media #{$lg-screen}{
								bottom: -5vw;
							}

							@media #{$md-screen-p}{
								bottom: -50px;
							}
							@media #{$md-screen-l}{
								bottom: -50px;
							}
						}
					}
				}		
			}
		}
	}
}

.main-overlay{
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $blue-bg;
	z-index: 99999;
	transition: all 0.5s ease;

	&.hide{
		opacity: 0;
		visibility: hidden;
	}

	.sk-circle {
		// margin: 100px auto;
		width: 40px;
		height: 40px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
	}
	.sk-circle .sk-child {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}
	.sk-circle .sk-child:before {
	  content: '';
	  display: block;
	  margin: 0 auto;
	  width: 15%;
	  height: 15%;
	  background-color: $white;
	  border-radius: 100%;
	  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
	          animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
	}
	.sk-circle .sk-circle2 {
	  -webkit-transform: rotate(30deg);
	      -ms-transform: rotate(30deg);
	          transform: rotate(30deg); }
	.sk-circle .sk-circle3 {
	  -webkit-transform: rotate(60deg);
	      -ms-transform: rotate(60deg);
	          transform: rotate(60deg); }
	.sk-circle .sk-circle4 {
	  -webkit-transform: rotate(90deg);
	      -ms-transform: rotate(90deg);
	          transform: rotate(90deg); }
	.sk-circle .sk-circle5 {
	  -webkit-transform: rotate(120deg);
	      -ms-transform: rotate(120deg);
	          transform: rotate(120deg); }
	.sk-circle .sk-circle6 {
	  -webkit-transform: rotate(150deg);
	      -ms-transform: rotate(150deg);
	          transform: rotate(150deg); }
	.sk-circle .sk-circle7 {
	  -webkit-transform: rotate(180deg);
	      -ms-transform: rotate(180deg);
	          transform: rotate(180deg); }
	.sk-circle .sk-circle8 {
	  -webkit-transform: rotate(210deg);
	      -ms-transform: rotate(210deg);
	          transform: rotate(210deg); }
	.sk-circle .sk-circle9 {
	  -webkit-transform: rotate(240deg);
	      -ms-transform: rotate(240deg);
	          transform: rotate(240deg); }
	.sk-circle .sk-circle10 {
	  -webkit-transform: rotate(270deg);
	      -ms-transform: rotate(270deg);
	          transform: rotate(270deg); }
	.sk-circle .sk-circle11 {
	  -webkit-transform: rotate(300deg);
	      -ms-transform: rotate(300deg);
	          transform: rotate(300deg); }
	.sk-circle .sk-circle12 {
	  -webkit-transform: rotate(330deg);
	      -ms-transform: rotate(330deg);
	          transform: rotate(330deg); }
	.sk-circle .sk-circle2:before {
	  -webkit-animation-delay: -1.1s;
	          animation-delay: -1.1s; }
	.sk-circle .sk-circle3:before {
	  -webkit-animation-delay: -1s;
	          animation-delay: -1s; }
	.sk-circle .sk-circle4:before {
	  -webkit-animation-delay: -0.9s;
	          animation-delay: -0.9s; }
	.sk-circle .sk-circle5:before {
	  -webkit-animation-delay: -0.8s;
	          animation-delay: -0.8s; }
	.sk-circle .sk-circle6:before {
	  -webkit-animation-delay: -0.7s;
	          animation-delay: -0.7s; }
	.sk-circle .sk-circle7:before {
	  -webkit-animation-delay: -0.6s;
	          animation-delay: -0.6s; }
	.sk-circle .sk-circle8:before {
	  -webkit-animation-delay: -0.5s;
	          animation-delay: -0.5s; }
	.sk-circle .sk-circle9:before {
	  -webkit-animation-delay: -0.4s;
	          animation-delay: -0.4s; }
	.sk-circle .sk-circle10:before {
	  -webkit-animation-delay: -0.3s;
	          animation-delay: -0.3s; }
	.sk-circle .sk-circle11:before {
	  -webkit-animation-delay: -0.2s;
	          animation-delay: -0.2s; }
	.sk-circle .sk-circle12:before {
	  -webkit-animation-delay: -0.1s;
	          animation-delay: -0.1s; }


}

.menu-burger{
	width: 40px;
	height: 45px;
	position: absolute;
	// margin: 50px auto;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
	z-index: 199;
	top: 30px;
	left: 30px;

	@media #{$md-screen-l}{
		display: none;
	}
	@media #{$md-screen-p}{
		display: none;
	}

	span {
		display: block;
		position: absolute;
		height: 3px;
		width: 100%;
		background: $blue-bg;
		border-radius: 9px;
		opacity: 1;
		left: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .25s ease-in-out;
		-moz-transition: .25s ease-in-out;
		-o-transition: .25s ease-in-out;
		transition: .25s ease-in-out;
		
		&:nth-child(1) {
		  top: 0px;
		}
		&:nth-child(2){
		  top: 10px;	
		}
		&:nth-child(3) {
		  top: 10px;
		}

		&:nth-child(4) {
		  top: 20px;
		}
	}

	&.open{
		span{
			background-color: $white;

			&:nth-child(1) {
			  top: 18px;
			  width: 0%;
			  left: 50%;
			}

			&:nth-child(2) {
			  -webkit-transform: rotate(45deg);
			  -moz-transform: rotate(45deg);
			  -o-transform: rotate(45deg);
			  transform: rotate(45deg);
			}

			&:nth-child(3) {
			  -webkit-transform: rotate(-45deg);
			  -moz-transform: rotate(-45deg);
			  -o-transform: rotate(-45deg);
			  transform: rotate(-45deg);
			}

			&:nth-child(4) {
			  top: 18px;
			  width: 0%;
			  left: 50%;
			}
		}
	}
}
.section{
	overflow: hidden;

	&.section-8{
		.menu-burger{
			span{
				background-color: $white;
			}
		}
	}

	.menu-additional{
		display: flex;
		position: absolute;
		top: 0;
		padding: 45px 50px 35px 50px;
		background-color: $blue-bg;
		width: 100%;
		color: $white;
		transform: translate3d(0, -100%, 0);
		transition: all 1.6s ease;
		z-index: 190;

		&.show{
			// transform: translate3d(0,0,0);
			animation: menuAdd 0.45s ease;
			animation-fill-mode: forwards;
		}
		&.close{
			// transform: translate3d(0,0,0);
			animation: menuAddClose 0.45s ease;
			animation-fill-mode: forwards;
		}

		.menu-menu-1-en-container,
		.menu-menu-1-de-container,
		.menu-menu-1-ru-container,
		.menu-menu-1-container{
				width: 50%;
				margin-top: -3px;

				.menu{
					display: flex;
					justify-content: space-around;

					li{
						font-size: 20px;
					    font-weight: 300;
					    text-transform: uppercase;
						a{
							color: $white;
						}
					}
				}
			
		}
		.menu-menu-2-container,
		.menu-menu-2-en-container,
		.menu-menu-2-de-container,
		.menu-menu-2-ru-container{
			width: 50%;
			margin-top: -3px;

			.menu{
				display: flex;
				justify-content: space-around;
				
				li{
					font-size: 20px;
				    font-weight: 300;
				    text-transform: uppercase;

					a{
						color: $white;
					}
				}
			}
		}
	}
}
.fp-tableCell{
	position: relative;
	overflow: hidden;
	height: 100vh!important;
}
.menu-lang{
	position: absolute;
	right: 30px;
	top: 48px;
	color: $white;
	z-index: 1;

	@media #{$lg-screen}{
		top: 21px;
	}
	@media #{$d1180}{
		right: 10px;
	}
	@media #{$md-screen-p}{
		top: 5.5vh;
		transform: translateY(-11px);
	}
	@media #{$md-screen-l}{
		top: 21px;
	}
	@media #{$sm-screen-p}{
		top: 26px;
	}
	@media #{$sm-screen-l}{
		// top: 13px;
	}

	.menu-jezyki-container{
		.menu{

			@media #{$md-screen-p}{
				display: flex;
				
			}
			@media #{$md-screen-l}{
				display: flex;

			}

			@media #{$sm-screen-p}{
				display: flex;
				
			}
			@media #{$sm-screen-l}{
				display: flex;

			}
			.pll-parent-menu-item{
				position: relative;
			    font-size: 20px;
			    font-weight: 300;
			    text-transform: uppercase;
			    cursor: pointer;

				a{
					color: $white;
				}
			}

			li{
				@media #{$md-screen-p}{
					margin: 0 0 0 20px;					
				}
				@media #{$md-screen-l}{
					margin: 0 0 0 20px;
				}
				@media #{$sm-screen-p}{
					margin: 0 0 0 10px;					
				}
				@media #{$sm-screen-l}{
					margin: 0 0 0 10px;
				}
			}

			.sub-menu{
				// visibility: hidden;
				// opacity: 0;
				transition: all 0.4s ease;
				margin-top: 10px;

				&.show{
					visibility: visible;
					opacity: 1;
				}

				li{
					margin-bottom: 5px;
				    font-size: 20px;
				    font-weight: 300;
				    text-transform: uppercase;

					@media #{$sm-screen-p}{
						margin: 0 10px;
						
					}
					@media #{$sm-screen-l}{
						margin: 0 10px;

					}

					a{
						color: $white;
					}
				}
			}
		}
	}
}
.error-404{
	.page-header{
		padding: 10vw 20px;
		box-sizing: border-box;

		h1{
			font-size: 20px;
			color: $white;
			display: block;
			margin: 0 auto;
			font-weight: 300;
			text-align: center;
		}
	}
}
div.pdfemb-viewer{
	width: 95%!important;

	@media #{$md-screen-p}{
		width: 100%!important
	}
	@media #{$md-screen-l}{
		width: 100%!important
	}
}